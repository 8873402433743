<template>
  <div class="windowBody">
    <div class="loginAndRegisterCommonContent" id="loginWindow">
      <WindowHeader>{{ $t("login") }}</WindowHeader>
      <div class="inputAreaBox">
        <el-form @submit.stop="() => false">
          <div class="inputOuter">
            <el-form-item prop="userName">
              <CommonInput @inputChange="getAccountText">
                <span class="iconfont_Me icon-user-fill iconStyle"></span>
              </CommonInput>
            </el-form-item>
          </div>
          <div class="inputOuter">
            <el-form-item prop="userPassword">
              <CommonInput @inputChange="getPasswordText" :passwordInput="true">
                <span class="iconfont_Me icon-password-fill iconStyle"></span>
              </CommonInput>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <div class="loginOperationArea">
        <div class="operationWidget" @click.stop="enterTheForgetPwdProcess">
          {{ $t("forgetPwd") }}
        </div>
      </div>
      <div
        class="loginButton"
        :class="allowedLogin ? 'commomButtonHover' : ''"
        @click.stop="startLogin"
      >
        <span class="iconfont_Me icon-check iconStyle"></span>
      </div>
    </div>
    <div class="windowFooter" :style="{ marginBottom: '50px' }">
      <div class="backToPrevious" @click.stop="enterTheInitialWindow">
        <span class="iconfont_Me icon-return iconStyle"></span>
      </div>
      <!-- <div class="startRegister" @click.stop="enterTheRegisterProcess">
        <span class="iconfont_Me icon-plus iconStyle"></span>
        {{$t('register')}} >
      </div> -->
    </div>
  </div>
</template>
<script>
import CommonInput from "../../components/inputComponent/commonInput";
import WindowHeader from "./windowHeader";
export default {
  name: "loginWindow",
  components: {
    CommonInput,
    WindowHeader,
  },
  computed: {
    // 是否可以登录
    allowedLogin() {
      return this.userName !== "" && this.userPassword !== "";
    },
  },
  data() {
    return {
      // 账号
      userName: "",
      // 密码
      userPassword: "",
    };
  },
  methods: {
    // 获取输入的账号文本
    getAccountText(text) {
      this.userName = text;
    },
    // 获取输入的密码文本
    getPasswordText(text) {
      this.userPassword = text;
    },
    // 开始执行登录操作
    startLogin() {
      if (this.allowedLogin) {
        let login = {
          userName: this.userName,
          userPassword: this.userPassword,
          remember: true,
        };
        this.$emit("handleLogin", login);
      }
    },
    // 点击返回初始窗口
    enterTheInitialWindow() {
      this.$emit("enterTheInitialWindow");
    },
    // 点击进入注册流程
    enterTheRegisterProcess() {
      this.$emit("enterTheRegisterProcess");
    },
    // 点击忘记密码按钮
    enterTheForgetPwdProcess() {
      // 这里给出忘记密码无法解密的提示
      this.$confirm(this.$t("resettingPasswordNoEncrypted"), this.$t("tips"), {
        confirmButtonText: " ",
        type: "warning",
        customClass: "melinked-tips",
        showCancelButton: false,
        confirmButtonClass: "el-icon-right my-confirm-btn",
      })
        .then(() => {
          this.$emit("enterTheForgetPwdProcess");
        })
        .catch(() => {});
    },
  },
};
</script>
<style lang="stylus" scoped>
.windowBody
  width 100%;
  height 100%;
  padding 0 45px;
  box-sizing border-box;
  display flex;
  flex-flow column;
  align-items center;
  justify-content space-between;
  .loginOperationArea
    width 100%;
    height 50px;
    display flex;
    align-items center;
    justify-content flex-end;
    .operationWidget
      cursor pointer
      font-size 14px;
      color #343434;
      user-select none;
  .loginButton
    width 100%;
    height 46px;
    margin-top 40px;
    border-radius 4px;
    overflow hidden;
    background #E6E6E6;
    display flex;
    align-items center;
    justify-content center;
    cursor not-allowed;
    .iconStyle
      color #FFFFFF;
      font-size 16px;
</style>
