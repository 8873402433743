var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "windowBody" }, [
    _c(
      "div",
      {
        staticClass: "loginAndRegisterCommonContent",
        attrs: { id: "retrieveMethodWindow" }
      },
      [
        _c("WindowHeader", [_vm._v(_vm._s(_vm.$t("forgetPwd")))]),
        _c("div", { staticClass: "retrieveMethodTips" }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$t("chooseHowToRetrieveYourPassword")) +
              "\n    "
          )
        ]),
        _c(
          "div",
          { staticClass: "retrieveMethodArea" },
          _vm._l(_vm.accountSecureInfomationArr, function(item, index) {
            return _c(
              "div",
              {
                key: item.methodName,
                staticClass: "methodCheckedItem",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.selectedThisMethod(index)
                  }
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "selectedItemIcon" },
                  [
                    _c("el-image", {
                      staticStyle: { width: "100%", height: "100%" },
                      attrs: { src: item.iconSrc, fit: "fill" }
                    })
                  ],
                  1
                ),
                _c("div", { staticClass: "selectedItemTitle" }, [
                  _vm._v("\n          " + _vm._s(item.title) + "\n        ")
                ]),
                _c(
                  "div",
                  { staticClass: "selectedMark" },
                  [
                    item.isSelected
                      ? _c("el-image", {
                          staticStyle: { width: "100%", height: "100%" },
                          attrs: {
                            src: require(`assets/images/newLogin/hasSelected.png`),
                            fit: "fill"
                          }
                        })
                      : _vm._e(),
                    !item.isSelected
                      ? _c("el-image", {
                          staticStyle: { width: "100%", height: "100%" },
                          attrs: {
                            src: require(`assets/images/newLogin/unSelected.png`),
                            fit: "fill"
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ]
            )
          }),
          0
        ),
        _c(
          "div",
          {
            staticClass: "nextStepButton",
            class: _vm.allowedNextStep ? "commomButtonHover" : "",
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.enterTheSelectedMethod.apply(null, arguments)
              }
            }
          },
          [_c("span", { staticClass: "iconfont_Me icon-next iconStyle" })]
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "windowFooter", style: { marginBottom: "50px" } },
      [
        _c(
          "div",
          {
            staticClass: "backToPrevious",
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.enterTheLoginProcess.apply(null, arguments)
              }
            }
          },
          [_c("span", { staticClass: "iconfont_Me icon-return iconStyle" })]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }