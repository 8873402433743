var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "windowBody" },
    [
      _c("WindowHeader", [
        _vm._v(_vm._s(_vm.$t("bind")) + _vm._s(_vm.$t("phone")))
      ]),
      _c(
        "div",
        { staticClass: "verifyPhone" },
        [
          _c("VerifyPhone", {
            on: {
              selectThisAreaCode: _vm.selectThisAreaCode,
              getInputText: _vm.getNewPhoneNumberText,
              getVerificationText: _vm.verificationCodeForNewPhone
            }
          }),
          _c(
            "div",
            {
              staticClass: "nextButton",
              class: _vm.allowedBindPhone ? "commomButtonHover" : "",
              style: {
                cursor: _vm.allowedBindPhone ? "pointer" : "not-allowed"
              },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.startNextStep.apply(null, arguments)
                }
              }
            },
            [_c("span", { staticClass: "iconfont_Me icon-check iconStyle" })]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }