<template>
  <div class="windowBody">
    <div class="loginAndRegisterCommonContent" id="retrieveMethodWindow">
      <WindowHeader>{{ $t("forgetPwd") }}</WindowHeader>
      <div class="retrieveMethodTips">
        {{ $t("chooseHowToRetrieveYourPassword") }}
      </div>
      <div class="retrieveMethodArea">
        <div
          class="methodCheckedItem"
          v-for="(item, index) in accountSecureInfomationArr"
          :key="item.methodName"
          @click.stop="selectedThisMethod(index)"
        >
          <div class="selectedItemIcon">
            <el-image
              style="width: 100%; height: 100%"
              :src="item.iconSrc"
              fit="fill"
            ></el-image>
          </div>
          <div class="selectedItemTitle">
            {{ item.title }}
          </div>
          <div class="selectedMark">
            <el-image
              v-if="item.isSelected"
              style="width: 100%; height: 100%"
              :src="require(`assets/images/newLogin/hasSelected.png`)"
              fit="fill"
            ></el-image>
            <el-image
              v-if="!item.isSelected"
              style="width: 100%; height: 100%"
              :src="require(`assets/images/newLogin/unSelected.png`)"
              fit="fill"
            ></el-image>
          </div>
        </div>
      </div>
      <div
        class="nextStepButton"
        :class="allowedNextStep ? 'commomButtonHover' : ''"
        @click.stop="enterTheSelectedMethod"
      >
        <span class="iconfont_Me icon-next iconStyle"></span>
      </div>
    </div>
    <div class="windowFooter" :style="{ marginBottom: '50px' }">
      <div class="backToPrevious" @click.stop="enterTheLoginProcess">
        <span class="iconfont_Me icon-return iconStyle"></span>
      </div>
    </div>
  </div>
</template>
<script>
import WindowHeader from "./windowHeader";
export default {
  name: "retrieveMethodWindow",
  components: {
    WindowHeader,
  },
  computed: {
    // 当前的找回方式集合
    accountSecureInfomationArr() {
      let accountSecureInfomationArr = [];
      let accountSecureInfomation = this.$store.state.loginAndRegister
        .accountSecureInfomation;
      for (let i in accountSecureInfomation) {
        let secureInfomationItem = {
          isSelected: false,
        };
        switch (i) {
          case "securePhone":
            secureInfomationItem.methodName = i;
            secureInfomationItem.iconSrc = require(`assets/images/newLogin/${i}.png`);
            secureInfomationItem.title = `+${accountSecureInfomation[i]}`;
            break;
          case "secureEmail":
            secureInfomationItem.methodName = i;
            secureInfomationItem.iconSrc = require(`assets/images/newLogin/${i}.png`);
            secureInfomationItem.title = accountSecureInfomation[i];
            break;
          case "secureQuestionAnswers":
            secureInfomationItem.methodName = i;
            secureInfomationItem.iconSrc = require(`assets/images/newLogin/${i}.png`);
            secureInfomationItem.title = this.$t("secureQuestion");
            break;
        }
        accountSecureInfomationArr.push(secureInfomationItem);
      }
      return accountSecureInfomationArr;
    },
    // 是否可以进行下一步
    allowedNextStep() {
      return this.$store.state.loginAndRegister.retrieveMethodIndex !== "";
    },
  },
  props: {
    retrieveMethodWindowFlag: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    // 监听当前模块是否打开了，打开了默认选中第一个
    retrieveMethodWindowFlag: {
      handler(val, old) {
        if (val && val !== old) {
          this.$nextTick(() => {
            this.selectedThisMethod(0);
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    // 选择这个找回方式
    selectedThisMethod(index) {
      this.accountSecureInfomationArr.map((item) => {
        item.isSelected = false;
      });
      this.accountSecureInfomationArr[index].isSelected = true;
      this.$store.commit(
        "setRetrieveMethodIndex",
        this.accountSecureInfomationArr[index].methodName
      );
      this.$forceUpdate();
    },
    // 进入选中的方式
    enterTheSelectedMethod() {
      if (this.allowedNextStep) {
        this.$emit("enterTheSelectedMethod");
      }
    },
    // 返回登录窗口
    enterTheLoginProcess() {
      this.$store.commit("setRetrieveMethodIndex", "");
      this.$emit("enterTheForgetPwdProcess");
    },
  },
};
</script>
<style lang="stylus" scoped>
.selectedStyle-enter-active, .selectedStyle-leave-active
  transition: opacity .3s;
.selectedStyle-enter,.selectedStyle-leave-to
  opacity: 0;
.windowBody
  width 100%;
  height 100%;
  padding 0 45px;
  box-sizing border-box;
  display flex;
  flex-flow column;
  align-items center;
  justify-content space-between;
  .retrieveMethodTips
    font-size 12px;
    color #8F8F8F;
    text-align left;
  .retrieveMethodArea
    width 100%;
    overflow hidden;
    margin-top 50px;
    .methodCheckedItem
      height 55px;
      width 100%;
      cursor pointer;
      display flex;
      align-items center;
      justify-content flex-start;
      position relative;
      user-select none;
      .selectedItemIcon
        width 31px;
        height 31px;
        flex-shrink 0;
      .selectedItemTitle
        flex 1;
        min-width 0;
        height 100%;
        line-height 55px;
        box-sizing border-box;
        padding-left 15px;
        text-align left;
        font-size 16px;
        color #343434;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
      .selectedMark
        width 20px;
        height 20px;
        background #FFFFFF;
  .nextStepButton
    width 100%;
    height 46px;
    margin-top 50px;
    border-radius 4px;
    overflow hidden;
    background #E6E6E6;
    display flex;
    align-items center;
    justify-content center;
    cursor not-allowed;
    .iconStyle
      color #FFFFFF;
      font-size 16px;
</style>
