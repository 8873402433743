<template>
  <div
    class="mainBody"
    v-loading="isloading"
    element-loading-spinner="el-icon-loading"
    element-loading-background="#FFFFFF"
  >
    <div
      class="teamHeader"
      :style="{
        'justify-content': hiddenBackButton ? 'flex-end' : 'space-between',
      }"
    >
      <div
        class="returnButton"
        @click="backToPrevious"
        v-if="!hiddenBackButton"
      >
        <span class="iconfont_Me icon-left iconStyle"></span>
      </div>
      <div class="closeButton">
        <span
          class="iconfont_Me icon-x iconStyle"
          @click.stop="closeDialog"
        ></span>
      </div>
    </div>
    <div class="view">
      <component
        :is="currentlyWindow"
        :type="type"
        @nextWindow="nextWindow"
        @confirm="confirm"
      ></component>
    </div>
  </div>
</template>
<script>
import InstructionsWindow from "./instructionsWindow.vue";
import RegisterWindow from "./registerWindow.vue";
import PrivacyPolicyBox from "./privacyPolicyBox.vue";
import Security from "./security.vue";
import BindPhone from "./bindPhone.vue";
import BindEmail from "./bindEmail.vue";
import BindQuestion from "./bindQuestion.vue";
import MemberRegister from "./memberRegister.vue";
import {
  teamManagerRegister,
  teamMemberRegister,
} from "../../../../api/newVersion/loginAndRegister/index";
import { close as serverClose } from "@/components/business/chat/server";
import { env } from "@/utils";
export default {
  name: "teamWindow",
  watch: {
    type: {
      handler(val) {
        if (val === 0) {
          this.currentlyWindow = "InstructionsWindow";
        } else {
          this.currentlyWindow = "MemberRegister";
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      currentlyWindow: "",
      isloading: false,
    };
  },
  computed: {
    register() {
      return this.type === 0
        ? this.$store.state.loginAndRegister.teamDefaultRegister
        : this.$store.state.loginAndRegister.memberDefaultRegister;
    },
    memberUsername() {
      return this.$store.state.team.memberUsername;
    },
    memberOldPassword() {
      return this.$store.state.team.memberOldPassword;
    },
    phone() {
      return this.register && this.register.phone ? this.register.phone : "";
    },
    email() {
      return this.register && this.register.email ? this.register.email : "";
    },
    questionFilled() {
      return this.register &&
        this.register.answers &&
        this.register.answers.length != 0
        ? true
        : false;
    },
    phoneFilled() {
      return this.phone !== "";
    },
    emailFilled() {
      return this.email !== "";
    },
    type() {
      return this.$store.state.team.teamWindowType;
    },
    teamWindowCloseFn() {
      return this.$store.state.team.teamWindowCloseFn;
    },
    hideTeamMemberBackButton() {
      return this.$store.state.team.hideTeamMemberBackButton;
    },
    // 团队成员是否更新过初始密码
    updatePassword() {
      return (
        this.$store.getters.userInfo &&
        (this.$store.getters.userInfo.identity !== "5" ||
          this.$store.getters.userInfo.updatePassword !== false)
      );
    },
    hiddenBackButton() {
      return (
        (!this.updatePassword && this.currentlyWindow == "MemberRegister") ||
        this.hideTeamMemberBackButton
      );
    },
    // 调用来源
    dialogSourceType() {
      return this.$store.state.loginAndRegister.dialogSourceType;
    },
    memberMailOriginProviderId() {
      return this.$store.state.team.memberMailOriginProviderId;
    },
  },
  components: {
    InstructionsWindow,
    RegisterWindow,
    PrivacyPolicyBox,
    Security,
    BindPhone,
    BindEmail,
    BindQuestion,
    MemberRegister,
  },
  beforeDestroy() {
    this.$store.commit("setHideTeamMemberBackButton", false);
    this.$store.commit("setMemberMailOriginProviderId", "");
  },
  methods: {
    nextWindow(next) {
      this.currentlyWindow = next;
    },
    backToPrevious() {
      switch (this.currentlyWindow) {
        case "InstructionsWindow":
          if (this.dialogSourceType == "email") {
            this.$emit("closeDialog");
            return;
          } else {
            this.$emit("enterTheInitialWindow");
          }
          break;
        case "MemberRegister":
          this.$store.commit("setMemberDefaultRegister", null);
          this.$emit("enterTheInitialWindow");
          break;
        case "RegisterWindow":
          this.$store.commit("setTeamDefaultRegister", null);
          this.currentlyWindow = "InstructionsWindow";
          break;
        case "PrivacyPolicyBox":
          this.currentlyWindow = "RegisterWindow";
          break;
        case "Security":
          switch (this.type) {
            case 0:
              this.currentlyWindow = "RegisterWindow";
              break;
            case 1:
              this.currentlyWindow = "MemberRegister";
              break;
          }
          break;
        case "BindPhone":
          let register_1 = _.cloneDeep(this.register);
          delete register_1.phone;
          delete register_1.phoneCode;
          delete register_1.countryCode;
          switch (this.type) {
            case 0:
              this.$store.commit("setTeamDefaultRegister", register_1);
              break;
            case 1:
              this.$store.commit("setMemberDefaultRegister", register_1);
              break;
          }
          this.currentlyWindow = "Security";
          break;
        case "BindEmail":
          let register_2 = _.cloneDeep(this.register);
          delete register_2.email;
          delete register_2.emailCode;
          switch (this.type) {
            case 0:
              this.$store.commit("setTeamDefaultRegister", register_2);
              break;
            case 1:
              this.$store.commit("setMemberDefaultRegister", register_2);
              break;
          }
          this.currentlyWindow = "Security";
          break;
        case "BindQuestion":
          let register_3 = _.cloneDeep(this.register);
          register_3.answers = [];
          switch (this.type) {
            case 0:
              this.$store.commit("setTeamDefaultRegister", register_3);
              break;
            case 1:
              this.$store.commit("setMemberDefaultRegister", register_3);
              break;
          }
          this.currentlyWindow = "Security";
          break;
        default:
          break;
      }
    },
    closeDialog() {
      this.$emit("enterTheInitialWindow");
      if (this.teamWindowCloseFn) {
        this.teamWindowCloseFn();
      }
      this.$nextTick(() => {
        this.$store.dispatch("commitCloseLoginAndRegisterDialog");
        // 如果是没有修改密码的团队成员，则直接退出登录
        setTimeout(() => {
          if (
            this.$store.getters.userInfo.identity === "5" &&
            this.$store.getters.userInfo.updatePassword === false
          ) {
            this.clearLoginInfo();
          }
        }, 500);
      });
    },
    //退出登录
    async clearLoginInfo() {
      // 关闭socket
      serverClose();
      // 清空状态管理器用户信息
      this.$store.commit("setUserinfo", {});
      try {
        window.localStorage.clear("memberName");
        window.localStorage.clear("MOP");
      } catch (error) {}
      // 清空第三方用户cookie信息
      if (this.$cookies.get("oauth_user_id")) {
        this.$cookies.remove("oauth_user_id");
      }
      await this.$http({
        url: "/helome/logout.do",
        method: "get",
      }).catch((e) => {});
      window.location.href = env("BASEURL");
    },
    confirm() {
      let params = {};
      if (this.type === 0) {
        params = {
          encryptedPassword: this.fnencrypt(this.register.firstPassword),
          userName: this.register.userName,
          password: this.register.firstPassword,
        };
      } else {
        params = {
          newEncryptedPassword: this.fnencrypt(this.register.firstPassword),
          oldEncryptedPassword: this.memberOldPassword,
          userName: this.memberUsername,
          password: this.register.firstPassword,
          device: "web",
        };
      }
      if (this.phoneFilled) {
        params.phone = this.register.phone;
        params.phoneCode = this.register.phoneCode;
        params.countryCode = this.register.countryCode;
      }
      if (this.emailFilled) {
        params.email = this.register.email;
        params.emailCode = this.register.emailCode;
      }
      if (this.questionFilled) {
        params.answers = this.register.answers;
      }
      if (this.memberMailOriginProviderId && this.type == 1) {
        params.memberMailOriginProviderId = this.memberMailOriginProviderId;
      }
      this.complete(params);
    },
    async complete(params) {
      try {
        if (this.isloading) {
          return;
        }
        this.isloading = true;
        let result = {};
        if (this.type === 0) {
          result = await teamManagerRegister(params);
        } else {
          // 关闭socket
          serverClose();
          result = await teamMemberRegister(params);
        }
        this.isloading = false;
        if (result.code == 200) {
          try {
            localStorage.removeItem("memberName");
            localStorage.removeItem("MOP");
          } catch (error) {}
          // 团长账号注册成功
          if (this.type === 0) {
            if (this.$store.getters.userInfo.id) {
              this.$store.commit("setConfirmTipsDialog", {
                showDialog: true,
                showText: this.$t("teamManagementSuccess_loggedIn", {
                  n: this.$store.getters.userInfo.name,
                }),
                confirmFn: () => {
                  let login = {
                    userName: params.userName,
                    userPassword: params.password,
                    remember: true,
                  };
                  if (this.dialogSourceType == "email") {
                    window.localStorage.setItem(
                      "memberRegister_first_time",
                      true
                    );
                  }
                  this.$emit("handleLogin", login);
                },
                closeFn: () => {
                  this.$emit("closeDialog");
                  this.$store.commit("setEmail_loginAndRegisterDialog", false);
                },
              });
            } else {
              let login = {
                userName: params.userName,
                userPassword: params.password,
                remember: true,
              };
              if (this.dialogSourceType == "email") {
                window.localStorage.setItem("memberRegister_first_time", true);
              }
              this.$emit("handleLogin", login);
            }
          } else {
            // 团队成员密码修改成功
            const userInfo = this.$store.getters.userInfo;
            userInfo.updatePassword = true;
            this.$store.commit("setUserinfo", userInfo);
            // 这里是判断因为可能出现有人绑定未修改过密码的团队邮箱，由于团队邮箱账号也是团队成员账号也会走这里，所以需要判断一下修改成功后需要自动打开邮箱模块
            try {
              const setEmailAccountPassword = localStorage.getItem(
                "setEmailAccountPassword"
              );
              if (setEmailAccountPassword) {
                window.localStorage.setItem("openEmail", true);
              }
            } catch (error) {}
            window.location.href = env("BASEURL");
          }
        } else {
          this.$message({
            type: "error",
            message: result.message,
          });
        }
      } catch (error) {}
    },
  },
};
</script>
<style lang="stylus" scoped>
.mainBody
  width 100%;
  height 100%;
  box-sizing border-box;
  display flex;
  flex-flow column;
  align-items center;
  justify-content flex-start;
  .teamHeader
    width: 100%;
    padding 15px 20px;
    overflow hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    .returnButton
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background: #E8E8E8
      cursor pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    .closeButton
      width: 24px;
      height: 24px;
      display: flex;
      cursor pointer;
      align-items: center;
      justify-content: center;
  .view
    flex: 1;
    width: 100%;
    padding 15px 44px 0 44px;
    box-sizing: border-box;
    overflow hidden;
    .windowBody
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
</style>
