var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "windowBody" }, [
    _c("div", { staticClass: "position" }, [_c("WindowHeader")], 1),
    _c("div", { staticClass: "format" }, [
      _c(
        "div",
        {
          staticClass: "instructionsBox",
          style: { "word-break": _vm.languageCode == 1 ? "" : "keep-all" }
        },
        [
          _c("div", { staticClass: "teamTitle" }, [
            _vm._v(_vm._s(_vm.$t("teamDescriptionTitle")))
          ]),
          _c(
            "div",
            { staticClass: "cont", staticStyle: { "padding-top": "23px" } },
            [
              _vm._v(
                "\n        1、" +
                  _vm._s(_vm.$t("teamDescriptionContent_1")) +
                  "\n      "
              )
            ]
          ),
          _c(
            "div",
            { staticClass: "cont", staticStyle: { "padding-top": "32px" } },
            [
              _vm._v(
                "\n        2、" +
                  _vm._s(_vm.$t("teamDescriptionContent_2")) +
                  "\n      "
              )
            ]
          ),
          _c("div", { staticClass: "newFunction" }, [
            _c("img", {
              staticClass: "icon",
              attrs: {
                src: require("../../../../assets/images/newLogin/newFunction.png")
              }
            }),
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("teamDescriptionContent_newFunction")) +
                "\n      "
            )
          ])
        ]
      ),
      _c(
        "div",
        {
          staticClass: "nextButton",
          class: _vm.allowedRegister ? "commomButtonHover" : "",
          style: {
            cursor: _vm.allowedRegister ? "pointer" : "not-allowed"
          },
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.startRegister.apply(null, arguments)
            }
          }
        },
        [
          _vm.allowedRegister
            ? _c("span", {
                staticClass: "iconfont_Me icon-tubiaozhizuomoban iconStyle"
              })
            : _c("span", { staticClass: "countdown" }, [
                _vm._v(_vm._s(_vm.time) + "s")
              ])
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }