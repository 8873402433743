<template>
  <div class="windowBody">
    <div class="loginAndRegisterCommonContent" id="verifyEmailWindow">
      <WindowHeader>{{$t('verification')}}{{$t('email')}}</WindowHeader>
      <div class="inputAreaBox">
        <div class="inputOuter">
          <CommonInput
            :readOnly="true"
            :defaultInputText="$store.state.loginAndRegister.accountSecureInfomation.secureEmail"
          >
            <el-image
              style="width: 16px; height: 13px"
              :src="require('../../../assets/images/newLogin/emailIcon.png')"
              fit="fill"></el-image>
          </CommonInput>
        </div>
        <div class="inputOuter verificationCodeBox">
          <div class="leftInputBox">
            <CommonInput @inputChange="verificationCode">
              <el-image
                style="width: 14px; height: 16px"
                :src="require('../../../assets/images/newLogin/securityIcon.png')"
                fit="fill"></el-image>
            </CommonInput>
          </div>
          <div 
            class="rightSendButton"
            :class="hasSendVerificationCode?'notAllowedStyle':''"
            @click.stop="sendVerificationCode"
          >
            <el-image
              v-if="!hasSendVerificationCode"
              style="width: 16px; height: 16px"
              :src="require('../../../assets/images/newLogin/sendIcon.png')"
              fit="fill"></el-image>
            <span v-else class="countdownStyle">{{countdownText}}S</span>
          </div>
        </div>
      </div>
      <div class="nextStepButton" :class="allowedNextStep?'commomButtonHover':''" @click.stop="startNextStep">
        <span class="iconfont_Me icon-next iconStyle"></span>
      </div>
    </div>
    <div class="windowFooter" :style="{marginBottom:'50px'}">
      <div class="backToPrevious" @click.stop="goBackToThePreviousStep">
         <span class="iconfont_Me icon-return iconStyle"></span>
      </div>
    </div>
  </div>
</template>
<script>
import WindowHeader from "./windowHeader";
import CommonInput from "../../components/inputComponent/commonInput";
import { sendValidateCode, checkValidateCode } from "@/api/newVersion/loginAndRegister";
export default {
  name: "verifyMobilePhoneWindow",
  components: {
    WindowHeader,
    CommonInput
  },
  data() {
    return {
      // 已发送验证码
      hasSendVerificationCode: false,
      // 发送倒计时
      countdownText: 0,
      // 倒计时定时器对象
      countdownTimer: null,
      // 输入的验证码文本
      verificationText: ''
    }
  },
  watch: {
    // 监听已发送验证码,开始倒计时
    hasSendVerificationCode: {
      handler(val,old) {
        if(val && val !== old) {
          clearTimeout(this.countdownTimer);
          this.countdownText = 60;
          this.handleCountdown();
        }
      },
      deep: true
    }
  },
  computed: {
    // 是否输入完验证码
    allowedNextStep() {
      return this.globalTrim(this.verificationText) !== '';
    }
  },
  methods: {
    // 返回上一步
    goBackToThePreviousStep() {
      // 清除验证码记录
      this.$store.commit("setVerificationCodeForRetrievePassword","");
      // 如果设置了多种找回方式，就返回选择找回方式窗口，否则就返回验证登录账号窗口
      if(this.$store.state.loginAndRegister.secureMethodCount > 1) {
        this.$emit('enterTheRetrieveMethodProcess');
      } else {
        this.$emit('enterTheForgetPwdProcess');
      }
    },
    // 检查验证码是否正确，正确的话就进入下一步
    async startNextStep() {
      if(this.allowedNextStep) {
        let params = {
          code: this.globalTrim(this.verificationText),
          email: this.$store.state.loginAndRegister.accountSecureInfomation.secureEmail,
        };
        let result = await checkValidateCode(params);
        if ( result.code === 200) {
          // 先把定时器处理了
          clearTimeout(this.countdownTimer);
          // 记录验证码，用于修改密码
          this.$store.commit("setVerificationCodeForRetrievePassword",this.globalTrim(this.verificationText));
          // 验证成功，跳转密码设置窗口
          this.$emit('enterTheSetNewPasswordProcess');
        } else {
          this.$message({
            type: 'error',
            message: result.message || result.data.message
          });
        }
      }
    },
    // 接收输入的验证码
    verificationCode(text) {
      this.verificationText = text;
    },
    // 倒计时计算操作
    handleCountdown() {
      this.countdownTimer = setTimeout(() => {
        if(this.countdownText > 1) {
          this.countdownText--;
          this.handleCountdown();
        } else {
          clearTimeout(this.countdownTimer);
          this.hasSendVerificationCode = false;
        }
      },1000)
    },
    // 发送验证码
    async sendVerificationCode() {
      // 如果还在倒计时中，就不能再发送
      if(this.hasSendVerificationCode) {
        return;
      }
      try {
        let params = {
          email: this.$store.state.loginAndRegister.accountSecureInfomation.secureEmail,
        };
        let result = await sendValidateCode(params);
        if(result.code === 200) {
          this.hasSendVerificationCode = true;
        } else {
          this.$message({
            type: "error",
            message: result.message
          })
        }
      }catch(e){}
    }
  }
}
</script>
<style lang="stylus" scoped>
  .windowBody
    width 100%;
    height 100%;
    padding 0 45px;
    box-sizing border-box;
    display flex;
    flex-flow column;
    align-items center;
    justify-content space-between;
    .verificationCodeBox
      display flex;
      align-items center;
      justify-content flex-start;
      .leftInputBox
        width 275px;
        height 100%;
        flex-shrink 0;
      .notAllowedStyle
        cursor not-allowed !important;
        &:hover
          background transparent !important;
        .countdownStyle
          color #33CC66;
          font-size 14px;
      .rightSendButton
        flex 1;
        height 100%;
        margin-left 5px;
        box-sizing border-box;
        border 1px solid #33CC66;
        border-radius 4px;
        user-select none;
        cursor pointer;
        display flex;
        align-items center;
        justify-content center;
        transition background .3s;
        &:hover
          background #F2FCF8;
    .nextStepButton
      width 100%;
      height 46px;
      margin-top 50px;
      border-radius 4px;
      overflow hidden;
      background #E6E6E6;
      display flex;
      align-items center;
      justify-content center;
      cursor not-allowed;
      .iconStyle
        color #FFFFFF;
        font-size 16px;

</style>