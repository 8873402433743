var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.isloading,
          expression: "isloading"
        }
      ],
      staticClass: "mainBody",
      attrs: {
        "element-loading-spinner": "el-icon-loading",
        "element-loading-background": "#FFFFFF"
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "teamHeader",
          style: {
            "justify-content": _vm.hiddenBackButton
              ? "flex-end"
              : "space-between"
          }
        },
        [
          !_vm.hiddenBackButton
            ? _c(
                "div",
                {
                  staticClass: "returnButton",
                  on: { click: _vm.backToPrevious }
                },
                [_c("span", { staticClass: "iconfont_Me icon-left iconStyle" })]
              )
            : _vm._e(),
          _c("div", { staticClass: "closeButton" }, [
            _c("span", {
              staticClass: "iconfont_Me icon-x iconStyle",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.closeDialog.apply(null, arguments)
                }
              }
            })
          ])
        ]
      ),
      _c(
        "div",
        { staticClass: "view" },
        [
          _c(_vm.currentlyWindow, {
            tag: "component",
            attrs: { type: _vm.type },
            on: { nextWindow: _vm.nextWindow, confirm: _vm.confirm }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }