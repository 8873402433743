<template>
  <div class="privacyPolicyBox">
    <PrivacyPolicy></PrivacyPolicy>
  </div>
</template>
<script>
import PrivacyPolicy from "../../../components/privacyPolicy/privacyPolicy.vue";
export default {
  name: "PrivacyPolicyBox",
  components: {
    PrivacyPolicy,
  },
};
</script>
<style lang="stylus" scoped>
.privacyPolicyBox
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
  &::-webkit-scrollbar
    width: 0px;
</style>
