<template>
  <div class="privacyAgreementBody">
    <div class="contentBox" v-for="(item, index) in contentArr" :key="index">
      <div class="contentTitle">
        {{ item.title }}
      </div>
      <div
        class="contentInner"
        :style="{ 'word-break': languageCode == 1 ? '' : 'keep-all' }"
      >
        {{ item.content }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "PrivacyPolicy",
  computed: {
    contentArr() {
      let contentArr = this.$store.getters.privacyContents;
      return contentArr;
    },
  },
  created() {
    this.languageCode = localStorage.getItem("langCode") || 1;
  },
  data() {
    return {
      languageCode: 1,
    };
  },
};
</script>
<style lang="stylus" scoped>
.privacyAgreementBody {
  width: 100%;
  min-height: 0px;

  .contentBox {
    width: 100%;
    font-size: 14px;

    .contentTitle {
      margin-top: 15px;
      line-height: 26px;
      color: #333333;
      font-weight: bold;
      word-break: keep-all;
    }

    .contentInner {
      margin-top: 5px;
      line-height: 24px;
      color: #777777;
    }
  }
}
</style>
