var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "windowBody" },
    [
      _c("WindowHeader", [_vm._v(_vm._s(_vm.$t("setNewPwd")))]),
      _c("div", { staticClass: "format" }, [
        _c(
          "div",
          {
            staticClass: "tip",
            style: {
              color: _vm.firstInputShowError ? "#F57575" : "#8F8F8F"
            }
          },
          [_vm._v("\n      " + _vm._s(_vm.$t("setPasswordTips")) + "\n    ")]
        ),
        _c("div", { staticClass: "top" }, [
          _c(
            "div",
            { staticClass: "formBox" },
            [
              _c(
                "el-form",
                {
                  on: {
                    submit: function($event) {
                      $event.stopPropagation()
                      return (() => false).apply(null, arguments)
                    }
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "inputOuter" },
                    [
                      _c(
                        "CommonInput",
                        {
                          attrs: {
                            passwordInput: true,
                            defaultInputText: _vm.firstPassword,
                            needInputshowError: _vm.firstInputShowError
                          },
                          on: { inputChange: _vm.getFirstPassword }
                        },
                        [
                          _c("span", {
                            staticClass:
                              "iconfont_Me icon-a-lujing13297 iconStyle"
                          })
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "inputOuter",
                      staticStyle: { "margin-bottom": "0" }
                    },
                    [
                      _c(
                        "CommonInput",
                        {
                          attrs: {
                            passwordInput: true,
                            defaultInputText: _vm.secondPassword,
                            needInputshowError: _vm.passwordNotTheSame
                          },
                          on: { inputChange: _vm.getSecondPassword }
                        },
                        [
                          _c("span", {
                            staticClass: "iconfont_Me icon-a-zu9564 iconStyle"
                          })
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "inputErrorTips",
                      style: { opacity: _vm.passwordNotTheSame ? 1 : 0 }
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("pwdNotSame")) +
                          "\n          "
                      )
                    ]
                  )
                ]
              )
            ],
            1
          )
        ]),
        _c(
          "div",
          {
            staticClass: "registerButton",
            class: _vm.allowedRegister ? "commomButtonHover" : "",
            style: {
              cursor: _vm.allowedRegister ? "pointer" : "not-allowed"
            },
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.startRegister.apply(null, arguments)
              }
            }
          },
          [
            _c("span", {
              staticClass: "iconfont_Me icon-tubiaozhizuomoban iconStyle"
            })
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }