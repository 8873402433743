<template>
  <div class="windowBody">
    <div class="position">
      <WindowHeader></WindowHeader>
    </div>
    <div class="format">
      <div
        class="instructionsBox"
        :style="{ 'word-break': languageCode == 1 ? '' : 'keep-all' }"
      >
        <div class="teamTitle">{{ $t("teamDescriptionTitle") }}</div>
        <div class="cont" style="padding-top:23px;">
          1、{{ $t("teamDescriptionContent_1") }}
        </div>
        <div class="cont" style="padding-top:32px;">
          2、{{ $t("teamDescriptionContent_2") }}
        </div>
        <div class="newFunction">
          <img
            class="icon"
            :src="require('../../../../assets/images/newLogin/newFunction.png')"
          />
          {{ $t("teamDescriptionContent_newFunction") }}
        </div>
      </div>
      <div
        class="nextButton"
        :class="allowedRegister ? 'commomButtonHover' : ''"
        @click.stop="startRegister"
        :style="{
          cursor: allowedRegister ? 'pointer' : 'not-allowed',
        }"
      >
        <span
          v-if="allowedRegister"
          class="iconfont_Me icon-tubiaozhizuomoban iconStyle"
        ></span>
        <span v-else class="countdown">{{ time }}s</span>
      </div>
    </div>
  </div>
</template>
<script>
import WindowHeader from "../windowHeader.vue";
export default {
  name: "InstructionsWindow",
  components: {
    WindowHeader,
  },
  computed: {
    // 是否可以登录
    allowedRegister() {
      return this.time === 0;
    },
  },
  data() {
    return {
      // 延迟检测
      timer: null,
      time: 5,
      languageCode: 1,
    };
  },
  watch: {},
  destroyed() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  },
  created() {
    this.languageCode = localStorage.getItem("langCode") || 1;
  },
  mounted() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.countdown();
  },
  methods: {
    countdown() {
      this.timer = setTimeout(() => {
        if (this.time > 0) {
          this.time--;
          this.countdown();
        } else if (this.time === 0) {
          clearTimeout(this.timer);
        }
      }, 1000);
    },
    startRegister() {
      if (this.time === 0) {
        this.$emit("nextWindow", "RegisterWindow");
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
.windowBody
  position: relative;
.position
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 10;
.format
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  padding-bottom: 30px;
  .instructionsBox
    width: 100%;
    flex: 1;
    .teamTitle
      font-size: 16px;
      font-weight: bold;
      color #200E32;
      text-align: left;
      letter-spacing: 0.8px;
      max-width: 298px;
    .cont
      font-size: 14px;
      line-height: 22px;
      text-align: left;
      color: #666666;
      letter-spacing: 0.7px;
    .newFunction
      width: 100%;
      font-size: 14px;
      line-height: 24px;
      text-align: left;
      color: #666666;
      letter-spacing: 0.7px;
      margin-top: 25px;
      vertical-align: middle;
      .icon
        display: inline-block;
        vertical-align: middle;
        width: 42px;
        height: 24px;
  .nextButton
    width: 360px;
    height: 46px;
    border-radius: 4px;
    background: rgba(0,0,0,0.10);
    display: flex;
    align-items: center;
    justify-content: center;
    .iconStyle
      font-size: 22px;
      color: #FFF;
    .countdown
      font-size: 17px;
      color: #200E32;
      letter-spacing: 0.85px;
</style>
