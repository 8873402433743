var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        center: "",
        "append-to-body": false,
        "destroy-on-close": true,
        "close-on-click-modal": false,
        visible: _vm.$store.state.loginAndRegister.loginAndRegisterDialog,
        "custom-class": `loginAndRegisterStyle_520`
      },
      on: {
        "update:visible": function($event) {
          return _vm.$set(
            _vm.$store.state.loginAndRegister,
            "loginAndRegisterDialog",
            $event
          )
        }
      }
    },
    [
      !_vm.privacyAgreementWindowFlag
        ? [
            !_vm.teamWindowFlag
              ? _c("div", { staticClass: "dialogHeader" }, [
                  _c("span", {
                    staticClass: "iconfont_Me icon-x iconStyle",
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.closeDialog.apply(null, arguments)
                      }
                    }
                  })
                ])
              : _vm._e(),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.isloading,
                    expression: "isloading"
                  }
                ],
                staticClass: "dialogBody",
                style: {
                  height: _vm.teamWindowFlag ? "100%" : "453px"
                },
                attrs: {
                  "element-loading-spinner": "el-icon-loading",
                  "element-loading-background": "#FFFFFF"
                }
              },
              [
                _c(
                  "transition",
                  { attrs: { name: "transitionStyle" } },
                  [
                    _vm.initialWindowFlag && !_vm.teamWindowFlag
                      ? _c("InitialWindow", {
                          on: {
                            enterTheLoginProcess: _vm.enterTheLoginProcess,
                            enterTheRegisterProcess:
                              _vm.enterTheRegisterProcess,
                            selectOtherLoginType: _vm.selectOtherLoginType,
                            enterTheTourist: _vm.enterTheTourist,
                            teamRegister: _vm.teamRegister
                          }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _c(
                  "transition",
                  { attrs: { name: "transitionStyle" } },
                  [
                    _vm.registerWindowFlag
                      ? _c("RegisterWindow", {
                          on: {
                            handlerRegister: _vm.handlerRegister,
                            enterTheInitialWindow: _vm.enterTheInitialWindow,
                            enterTheAgreementWindow: _vm.enterTheAgreementWindow
                          }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _c(
                  "transition",
                  { attrs: { name: "transitionStyle" } },
                  [
                    _vm.loginWindowFlag
                      ? _c("LoginWindow", {
                          on: {
                            handleLogin: _vm.handleLogin,
                            enterTheInitialWindow: _vm.enterTheInitialWindow,
                            enterTheRegisterProcess:
                              _vm.enterTheRegisterProcess,
                            enterTheForgetPwdProcess:
                              _vm.enterTheForgetPwdProcess
                          }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _c(
                  "transition",
                  { attrs: { name: "transitionStyle" } },
                  [
                    _vm.checkAccountWindowFlag
                      ? _c("CheckAccountWindow", {
                          on: {
                            enterTheLoginProcess: _vm.enterTheLoginProcess,
                            enterTheRetrieveMethodProcess:
                              _vm.enterTheRetrieveMethodProcess
                          }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _c(
                  "transition",
                  { attrs: { name: "transitionStyle" } },
                  [
                    _vm.retrieveMethodWindowFlag
                      ? _c("RetrieveMethodWindow", {
                          attrs: {
                            retrieveMethodWindowFlag:
                              _vm.retrieveMethodWindowFlag
                          },
                          on: {
                            enterTheForgetPwdProcess:
                              _vm.enterTheForgetPwdProcess,
                            enterTheSelectedMethod: _vm.enterTheSelectedMethod
                          }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _c(
                  "transition",
                  { attrs: { name: "transitionStyle" } },
                  [
                    _vm.verifyMobilePhoneWindowFlag
                      ? _c("VerifyMobilePhoneWindow", {
                          on: {
                            enterTheForgetPwdProcess:
                              _vm.enterTheForgetPwdProcess,
                            enterTheRetrieveMethodProcess:
                              _vm.enterTheRetrieveMethodProcess,
                            enterTheSetNewPasswordProcess:
                              _vm.enterTheSetNewPasswordProcess
                          }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _c(
                  "transition",
                  { attrs: { name: "transitionStyle" } },
                  [
                    _vm.verifyEmailWindowFlag
                      ? _c("VerifyEmailWindow", {
                          on: {
                            enterTheForgetPwdProcess:
                              _vm.enterTheForgetPwdProcess,
                            enterTheRetrieveMethodProcess:
                              _vm.enterTheRetrieveMethodProcess,
                            enterTheSetNewPasswordProcess:
                              _vm.enterTheSetNewPasswordProcess
                          }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _c(
                  "transition",
                  { attrs: { name: "transitionStyle" } },
                  [
                    _vm.verifyQAWindowFlag
                      ? _c("VerifyQAWindow", {
                          on: {
                            enterTheForgetPwdProcess:
                              _vm.enterTheForgetPwdProcess,
                            enterTheRetrieveMethodProcess:
                              _vm.enterTheRetrieveMethodProcess,
                            enterTheSetNewPasswordProcess:
                              _vm.enterTheSetNewPasswordProcess
                          }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _c(
                  "transition",
                  { attrs: { name: "transitionStyle" } },
                  [
                    _vm.setNewPasswordWindowFlag
                      ? _c("SetNewPasswordWindow", {
                          on: {
                            setNewPassword: _vm.setNewPassword,
                            enterTheSelectedMethod: _vm.enterTheSelectedMethod
                          }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _c(
                  "transition",
                  { attrs: { name: "transitionStyle" } },
                  [
                    _vm.setNewPasswordForThirdFlag
                      ? _c("SetNewPasswordForThird")
                      : _vm._e()
                  ],
                  1
                ),
                _c(
                  "transition",
                  { attrs: { name: "transitionStyle" } },
                  [
                    _vm.teamWindowFlag
                      ? _c("teamWindow", {
                          on: {
                            enterTheInitialWindow: _vm.enterTheInitialWindow,
                            handleLogin: _vm.handleLogin,
                            closeDialog: _vm.closeDialog
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          ]
        : _vm._e(),
      _c(
        "transition",
        { attrs: { name: "transitionStyle" } },
        [
          _vm.privacyAgreementWindowFlag
            ? _c("PrivacyAgreementWindow", {
                on: {
                  closeDialog: _vm.closeDialog,
                  enterTheRegisterProcess: _vm.enterTheRegisterProcess
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }