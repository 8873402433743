<template>
  <div class="windowBody">
    <div class="loginAndRegisterCommonContent" id="setNewPasswordForThird">
      <WindowHeader>{{$t("setNewPwd")}}</WindowHeader>
      <div class="setNewPasswordTips">
        {{$t("setPasswordTips")}}
      </div>
      <div class="setNewPasswordInputBox">
        <div class="inputOuter">
          <CommonInput 
            @inputChange="getFirstInputPasswordText" 
            :passwordInput="true"
            :needInputshowError="firstInputShowError"
          >
            <span class="iconfont_Me icon-password-fill iconStyle"></span>
          </CommonInput>
        </div>
        <div class="inputOuter" :style="{marginTop:'20px'}">
          <CommonInput  
            @inputChange="getSecondInputPasswordText" 
            :passwordInput="true"
            :needInputshowError="passwordNotTheSame"
          >
            <span class="iconfont_Me icon-password-fill iconStyle"></span>
          </CommonInput>
        </div>
      </div>
      <div class="inputErrorTips" :style="{opacity:passwordNotTheSame? 1 : 0}">
        {{$t("pwdNotSame")}}
      </div>
      <div class="submitNewPasswordButton" :class="allowedSubmit && !firstInputShowError?'commomButtonHover':''" @click.stop="startSubmitNewPassword">
        <span class="iconfont_Me icon-check iconStyle"></span>
      </div>
    </div>
    </div>
  </div>
</template>
<script>
import WindowHeader from "./windowHeader";
import { thirdAccountSetLoginPwd } from "@/api/newVersion/loginAndRegister";
import CommonInput from "../../components/inputComponent/commonInput";
export default {
  name: "setNewPasswordForThird",
  components: {
    WindowHeader,
    CommonInput
  },
  data() {
    return {
      // 第一次输入的密码文本
      firstInputPassword: '',
      // 第一个输入密码框显示错误提示
      firstInputShowError: false,
      // 第二次输入的密码文本
      secondInputPassword: '',
      // 两次输入的内容不一样
      passwordNotTheSame: false
    }
  },
  computed: {
    // 允许点击提交按钮
    allowedSubmit() {
      return this.firstInputPassword !== '' && this.secondInputPassword !== '';
    },
    // 验证密码是否符号规则的正则表达式
    verificationRegExp() {
      return new RegExp( /^(?![^a-zA-Z]+$)(?!\D+$)(?![a-zA-Z0-9]+$).{8,}$/ );
    }
  },
  methods: {
    // 获取第一次输入的密码文本
    getFirstInputPasswordText(text) {
      this.firstInputPassword = this.globalTrim(text);
      // 验证输入内容是否符合规范
      this.firstInputShowError = !this.verificationRegExp.test(this.firstInputPassword);
    },
    // 获取第二次输入的密码文本
    getSecondInputPasswordText(text) {
      this.secondInputPassword = this.globalTrim(text);
    },
    // 允许提交新密码
    async startSubmitNewPassword() {

      if(!this.allowedSubmit) {
        return;
      }
      if(this.firstInputPassword === this.secondInputPassword) {
        this.passwordNotTheSame = false;
        let params = {
          password: this.fnencrypt(this.firstInputPassword)
        };

        let result = await thirdAccountSetLoginPwd(params);
        if(result && result.code == 200 && result.message=="success"){
          this.$store.dispatch("commitCloseLoginAndRegisterDialog");
          this.$nextTick(() => {
            this.routeJump({ name: 'home' });
          })
        } else {
          this.$message({
            type : "error",
            message: result.message
          });
        }
      } else {
        this.passwordNotTheSame = true;
      }
    }
  }
}
</script>
<style lang="stylus" scoped>
  .windowBody
    width 100%;
    height 100%;
    padding 0 45px;
    box-sizing border-box;
    display flex;
    flex-flow column;
    align-items center;
    justify-content space-between;
    .setNewPasswordTips
      font-size 12px;
      color #8F8F8F;
      margin-top 2px;
      text-align left;
      word-break: keep-all;
    .setNewPasswordInputBox
      width 100%;
      min-height 0;
      margin-top 25px;
      overflow hidden;
      .inputOuter
        width 100%;
        height 46px;
        overflow hidden;
        margin-bottom 18px;
        &:last-child
          margin-bottom 0;
        .iconStyle
          color #666666;
          font-size 20px;
    .inputErrorTips
      width 100%;
      margin-top 10px;
      overflow hidden;
      color #FB0000;
      font-size 12px;
      text-align left;
    .submitNewPasswordButton
      width 100%;
      height 46px;
      margin-top 40px;
      border-radius 4px;
      overflow hidden;
      background #E6E6E6;
      display flex;
      align-items center;
      justify-content center;
      cursor not-allowed;
      .iconStyle
        color #FFFFFF;
        font-size 16px;

</style>