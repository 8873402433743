var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "windowBody" }, [
    _c(
      "div",
      {
        staticClass: "loginAndRegisterCommonContent",
        attrs: { id: "setNewPasswordForThird" }
      },
      [
        _c("WindowHeader", [_vm._v(_vm._s(_vm.$t("setNewPwd")))]),
        _c("div", { staticClass: "setNewPasswordTips" }, [
          _vm._v("\n      " + _vm._s(_vm.$t("setPasswordTips")) + "\n    ")
        ]),
        _c("div", { staticClass: "setNewPasswordInputBox" }, [
          _c(
            "div",
            { staticClass: "inputOuter" },
            [
              _c(
                "CommonInput",
                {
                  attrs: {
                    passwordInput: true,
                    needInputshowError: _vm.firstInputShowError
                  },
                  on: { inputChange: _vm.getFirstInputPasswordText }
                },
                [
                  _c("span", {
                    staticClass: "iconfont_Me icon-password-fill iconStyle"
                  })
                ]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputOuter", style: { marginTop: "20px" } },
            [
              _c(
                "CommonInput",
                {
                  attrs: {
                    passwordInput: true,
                    needInputshowError: _vm.passwordNotTheSame
                  },
                  on: { inputChange: _vm.getSecondInputPasswordText }
                },
                [
                  _c("span", {
                    staticClass: "iconfont_Me icon-password-fill iconStyle"
                  })
                ]
              )
            ],
            1
          )
        ]),
        _c(
          "div",
          {
            staticClass: "inputErrorTips",
            style: { opacity: _vm.passwordNotTheSame ? 1 : 0 }
          },
          [_vm._v("\n      " + _vm._s(_vm.$t("pwdNotSame")) + "\n    ")]
        ),
        _c(
          "div",
          {
            staticClass: "submitNewPasswordButton",
            class:
              _vm.allowedSubmit && !_vm.firstInputShowError
                ? "commomButtonHover"
                : "",
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.startSubmitNewPassword.apply(null, arguments)
              }
            }
          },
          [_c("span", { staticClass: "iconfont_Me icon-check iconStyle" })]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }