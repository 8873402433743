var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "windowBody" },
    [
      _c("WindowHeader", [_vm._v(_vm._s(_vm.$t("email")))]),
      _c("div", { staticClass: "format" }, [
        _c("div", { staticClass: "verifyEmail" }, [
          _c(
            "div",
            { staticClass: "inputOuter" },
            [
              _c(
                "CommonInput",
                {
                  attrs: { defaultInputText: _vm.email },
                  on: { inputChange: _vm.inputEmail }
                },
                [
                  _c("el-image", {
                    staticStyle: { width: "16px", height: "13px" },
                    attrs: {
                      src: require("../../../../assets/images/newLogin/emailIcon.png"),
                      fit: "fill"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("div", { staticClass: "inputOuter verificationCodeBox" }, [
            _c(
              "div",
              { staticClass: "leftInputBox" },
              [
                _c(
                  "CommonInput",
                  {
                    attrs: { defaultInputText: _vm.emailCode },
                    on: { inputChange: _vm.verificationCode }
                  },
                  [
                    _c("el-image", {
                      staticStyle: { width: "14px", height: "16px" },
                      attrs: {
                        src: require("../../../../assets/images/newLogin/securityIcon.png"),
                        fit: "fill"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "rightButtonStyle",
                class:
                  _vm.email == "" ||
                  !_vm.mailboxFormat ||
                  _vm.hasSendEmailVerificationCode
                    ? "rightSendButton_disabled"
                    : "rightSendButton",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.handlerGetVerCode.apply(null, arguments)
                  }
                }
              },
              [
                !_vm.hasSendEmailVerificationCode
                  ? _c("span", {
                      staticClass: "iconfont_Me icon-send-fill iconStyle"
                    })
                  : _c("span", { staticClass: "countdownStyle" }, [
                      _vm._v(_vm._s(_vm.countdownText) + "S")
                    ])
              ]
            )
          ])
        ]),
        _c(
          "div",
          {
            staticClass: "nextButton",
            class: _vm.allowedBindEmail ? "commomButtonHover" : "",
            style: {
              cursor: _vm.allowedBindEmail ? "pointer" : "not-allowed"
            },
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.startNextStep.apply(null, arguments)
              }
            }
          },
          [_c("span", { staticClass: "iconfont_Me icon-check iconStyle" })]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }