var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "windowBody" },
    [
      _c("WindowHeader", [
        _vm._v(_vm._s(_vm.$t("bind")) + _vm._s(_vm.$t("secureQuestion")))
      ]),
      _c("div", { staticClass: "format" }, [
        _c(
          "div",
          { staticClass: "questions" },
          _vm._l(_vm.setUpSecurityQuestions, function(item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "inputOuterStyle",
                style: {
                  "padding-top": item.showSelecter ? "9px" : "0px"
                }
              },
              [
                item.showSelecter
                  ? _c(
                      "QuestionListPopover",
                      {
                        attrs: {
                          handlerQuestionsList: _vm.listOfQuestions,
                          selecterIndex: index
                        },
                        on: { selectQuestion: _vm.selectQuestion }
                      },
                      [
                        _c("div", { staticClass: "questionSelecter" }, [
                          _c("div", { staticClass: "selecterLeft" }, [
                            _c(
                              "div",
                              { staticClass: "questionIcon" },
                              [
                                _c("el-image", {
                                  staticStyle: {
                                    width: "18px",
                                    height: "18px"
                                  },
                                  attrs: {
                                    src: require("../../../../assets/images/newLogin/questionIconLarge.png"),
                                    fit: "fill"
                                  }
                                })
                              ],
                              1
                            ),
                            _c("div", { staticClass: "questionText" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.$t("setAnswer")) +
                                  "\n              "
                              )
                            ])
                          ]),
                          _c("div", { staticClass: "selecterRight" }, [
                            _c("span", {
                              staticClass: "iconfont_Me icon-down iconStyle"
                            })
                          ])
                        ])
                      ]
                    )
                  : _vm._e(),
                !item.showSelecter
                  ? _c("QAInput", {
                      attrs: {
                        needCloseQuestion: true,
                        questionItem: item.secureQA,
                        autoFocus: !item.showSelecter,
                        indexInParent: index
                      },
                      on: {
                        getAnswer: _vm.getNewAnswer,
                        closeQuestionBox: _vm.closeQuestionBox
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          }),
          0
        ),
        _c(
          "div",
          {
            staticClass: "nextButton",
            class: _vm.allowedSetUpNewQA ? "commomButtonHover" : "",
            style: {
              cursor: _vm.allowedSetUpNewQA ? "pointer" : "not-allowed"
            },
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.startNextStep.apply(null, arguments)
              }
            }
          },
          [_c("span", { staticClass: "iconfont_Me icon-check iconStyle" })]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }