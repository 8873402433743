<template>
  <div class="windowBody">
    <WindowHeader>{{ $t("email") }}</WindowHeader>
    <div class="format">
      <div class="verifyEmail">
        <div class="inputOuter">
          <CommonInput @inputChange="inputEmail" :defaultInputText="email">
            <el-image
              style="width: 16px; height: 13px"
              :src="require('../../../../assets/images/newLogin/emailIcon.png')"
              fit="fill"
            ></el-image>
          </CommonInput>
        </div>
        <div class="inputOuter verificationCodeBox">
          <div class="leftInputBox">
            <CommonInput
              @inputChange="verificationCode"
              :defaultInputText="emailCode"
            >
              <el-image
                style="width: 14px; height: 16px"
                :src="
                  require('../../../../assets/images/newLogin/securityIcon.png')
                "
                fit="fill"
              ></el-image>
            </CommonInput>
          </div>
          <div
            class="rightButtonStyle"
            :class="
              email == '' || !mailboxFormat || hasSendEmailVerificationCode
                ? 'rightSendButton_disabled'
                : 'rightSendButton'
            "
            @click.stop="handlerGetVerCode"
          >
            <span
              v-if="!hasSendEmailVerificationCode"
              class="iconfont_Me icon-send-fill iconStyle"
            ></span>
            <span v-else class="countdownStyle">{{ countdownText }}S</span>
          </div>
        </div>
      </div>
      <div
        class="nextButton"
        :class="allowedBindEmail ? 'commomButtonHover' : ''"
        @click.stop="startNextStep"
        :style="{
          cursor: allowedBindEmail ? 'pointer' : 'not-allowed',
        }"
      >
        <span class="iconfont_Me icon-check iconStyle"></span>
      </div>
    </div>
  </div>
</template>
<script>
import WindowHeader from "../windowHeader.vue";
import CommonInput from "../../../components/inputComponent/commonInput.vue";
import { sendValidateCode } from "@/newVersion/components/accountSecurityDialog/api";
export default {
  name: "BindEmail",
  props: {
    /**
     * 0：默认从注册开始
     * 1：团队成员第一次登录，需要修改密码
     */
    type: {
      type: Number,
      default: 0,
    },
  },
  components: {
    WindowHeader,
    CommonInput,
  },
  data() {
    return {
      email: "",
      emailCode: "",
      countdownText: 60,
      countdownTimer: null,
      hasSendEmailVerificationCode: false,
    };
  },
  computed: {
    register() {
      return this.type === 0
        ? this.$store.state.loginAndRegister.teamDefaultRegister
        : this.$store.state.loginAndRegister.memberDefaultRegister;
    },
    allowedBindEmail() {
      return (
        this.globalTrim(this.emailCode) !== "" &&
        this.globalTrim(this.email) !== "" &&
        this.mailboxFormat
      );
    },
    mailboxFormat() {
      const req = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return req.test(this.email);
    },
  },
  watch: {
    hasSendEmailVerificationCode: {
      handler(val, old) {
        if (val && val !== old) {
          clearTimeout(this.countdownTimer);
          this.countdownText = 60;
          this.handleCountdown();
        }
      },
      deep: true,
    },
  },
  destroyed() {
    if (this.countdownTimer) {
      clearTimeout(this.countdownTimer);
    }
  },
  methods: {
    inputEmail(text) {
      this.email = text;
    },
    verificationCode(text) {
      this.emailCode = text;
    },
    async handlerGetVerCode() {
      if (this.email === "") {
        this.$message({
          type: "error",
          message: "The cell email number cannot be empty",
        });
        return;
      }
      if (!this.mailboxFormat) {
        this.$message({
          type: "error",
          message: this.$t("emailAddressIsIncorrect"),
        });
        return;
      }
      // 如果还在倒计时中，就不能再发送
      if (this.hasSendEmailVerificationCode) {
        return;
      }
      try {
        const params = {
          email: this.email,
        };
        const result = await sendValidateCode(params);
        if (result.code == 200) {
          this.hasSendEmailVerificationCode = true;
        } else {
          this.$message({
            type: "error",
            message: result.message,
          });
        }
      } catch (error) {}
    },
    // 倒计时计算操作
    handleCountdown() {
      this.countdownTimer = setTimeout(() => {
        if (this.countdownText > 1) {
          this.countdownText--;
          this.handleCountdown();
        } else {
          clearTimeout(this.countdownTimer);
          this.hasSendEmailVerificationCode = false;
        }
      }, 1000);
    },
    async startNextStep() {
      if (this.allowedBindEmail) {
        this.save();
        this.$emit("confirm");
      }
    },
    save() {
      let register = _.cloneDeep(this.register);
      register.email = this.globalTrim(this.email);
      register.emailCode = this.globalTrim(this.emailCode);
      if (this.type === 0) {
        this.$store.commit("setTeamDefaultRegister", register);
      } else {
        this.$store.commit("setMemberDefaultRegister", register);
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
.format
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  padding-bottom: 50px;
  .verifyEmail
    width 100%;
    overflow hidden;
    padding-top: 40px;
    .inputOuter
      width 100%;
      height 46px;
      overflow hidden;
      margin-bottom 18px;
      &:last-child
        margin-bottom 0;
      .iconStyle
        color #666666;
        font-size 20px;
    .verificationCodeBox
      display flex;
      align-items center;
      justify-content flex-start;
      .leftInputBox
        width 275px;
        height 100%;
        flex-shrink 0;
      .notAllowedStyle
        cursor not-allowed !important;
        &:hover
          background transparent !important;
        .countdownStyle
          color #33CC66;
          font-size 14px;
      .rightButtonStyle
        flex 1;
        height 100%;
        margin-left 5px;
        box-sizing border-box;
        border-radius 4px;
        user-select none;
        cursor pointer;
        display flex;
        align-items center;
        justify-content center;
        transition background .3s;
        .iconStyle
          font-size: 24px;
      .rightSendButton
        border 1px solid #33CC66;
        .iconStyle
          color #33CC66;
        &:hover
          background #F2FCF8;
      .rightSendButton_disabled
        border 1px solid rgba(0,0,0,0.1);
        cursor not-allowed;
        .iconStyle
          color rgba(0,0,0,0.1);
  .nextButton
    width: 360px;
    height: 46px;
    border-radius: 4px;
    background: rgba(0,0,0,0.10);
    display: flex;
    align-items: center;
    justify-content: center;
    .iconStyle
      font-size: 22px;
      color: #FFF;
</style>
