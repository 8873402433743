var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "windowBody", attrs: { id: "privacyAgreementWindow" } },
    [
      _c("div", { staticClass: "windowHeader" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.directly_enter_privacy_agreement_window,
                expression: "!directly_enter_privacy_agreement_window"
              }
            ],
            staticClass: "backToPrevious",
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.enterTheRegisterProcess.apply(null, arguments)
              }
            }
          },
          [_c("span", { staticClass: "iconfont_Me icon-left iconStyle" })]
        ),
        _c(
          "div",
          {
            staticClass: "closeDialog",
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.closeDialog.apply(null, arguments)
              }
            }
          },
          [_c("span", { staticClass: "iconfont_Me icon-x iconStyle" })]
        )
      ]),
      _c("div", { staticClass: "privacyPolicyBox" }, [_c("PrivacyPolicy")], 1)
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }