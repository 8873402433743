var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "windowBody" }, [
    _c(
      "div",
      { staticClass: "dialogLogo" },
      [
        _c("el-image", {
          staticStyle: { height: "100%" },
          attrs: { src: require("../../../assets/logo_2.png"), fit: "contain" }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "otherLogin" },
      _vm._l(_vm.otherLoginArr, function(item, index) {
        return _c("span", {
          key: item.className,
          staticClass: "iconfont_Me otherLoginButton",
          class: [item.className, item.fontClassName],
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.selectOtherLoginType(item.typeName)
            }
          }
        })
      }),
      0
    ),
    _c(
      "div",
      {
        staticClass: "loginButton commonStyle commomButtonHover",
        on: {
          click: function($event) {
            $event.stopPropagation()
            return _vm.enterTheLoginProcess.apply(null, arguments)
          }
        }
      },
      [_vm._v("\n    " + _vm._s(_vm.$t("login")) + "\n  ")]
    ),
    _c(
      "div",
      {
        staticClass: "registerButton bg commonStyle",
        style: { "margin-top": _vm.showTouristsAndTeams ? "" : "20px" },
        on: {
          click: function($event) {
            $event.stopPropagation()
            return _vm.enterTheRegisterProcess.apply(null, arguments)
          }
        }
      },
      [_vm._v("\n    " + _vm._s(_vm.$t("register")) + "\n  ")]
    ),
    _vm.showTouristsAndTeams
      ? _c(
          "div",
          {
            staticClass: "registerButton commonStyle",
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.enterTheTourist.apply(null, arguments)
              }
            }
          },
          [_vm._v("\n    " + _vm._s(_vm.$t("tourists")) + "\n  ")]
        )
      : _vm._e(),
    _vm.showTouristsAndTeams
      ? _c(
          "div",
          {
            staticClass: "teamRegisterButton commonStyle",
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.teamRegister.apply(null, arguments)
              }
            }
          },
          [_vm._v("\n    " + _vm._s(_vm.$t("teamRegistration")) + "\n  ")]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }