var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "windowBody" },
    [
      _c("WindowHeader"),
      _c("div", { staticClass: "format" }, [
        _c("div", { staticClass: "securityList" }, [
          _c("div", { staticClass: "tip" }, [
            _vm._v(_vm._s(_vm.$t("pleaseSetSecurity")))
          ]),
          _c("div", { staticClass: "item" }, [
            _c("div", { staticClass: "leftIcon" }, [
              _c("img", {
                style: {
                  width: "20px",
                  height: "20px"
                },
                attrs: {
                  src: require("../../../../assets/images/newSetting/phoneIcon.png")
                }
              })
            ]),
            _c("span", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.phoneFilled,
                  expression: "phoneFilled"
                }
              ],
              staticClass: "iconfont_Me icon-check iconStyle"
            }),
            _c("div", { staticClass: "rightBox" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.phoneFilled,
                      expression: "phoneFilled"
                    }
                  ],
                  staticClass: "text"
                },
                [_vm._v(_vm._s(_vm.phone))]
              ),
              _c(
                "div",
                {
                  staticClass: "edit",
                  on: {
                    click: function($event) {
                      return _vm.startSetting(0)
                    }
                  }
                },
                [
                  _c("span", {
                    staticClass: "iconfont_Me icon-edit1 iconStyle"
                  })
                ]
              )
            ])
          ]),
          _c("div", { staticClass: "item" }, [
            _c("div", { staticClass: "leftIcon" }, [
              _c("img", {
                style: {
                  width: "20px",
                  height: "20px"
                },
                attrs: {
                  src: require("../../../../assets/images/newSetting/emailIcon.png")
                }
              })
            ]),
            _c("span", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.emailFilled,
                  expression: "emailFilled"
                }
              ],
              staticClass: "iconfont_Me icon-check iconStyle"
            }),
            _c("div", { staticClass: "rightBox" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.emailFilled,
                      expression: "emailFilled"
                    }
                  ],
                  staticClass: "text"
                },
                [_vm._v(_vm._s(_vm.email))]
              ),
              _c(
                "div",
                {
                  staticClass: "edit",
                  on: {
                    click: function($event) {
                      return _vm.startSetting(1)
                    }
                  }
                },
                [
                  _c("span", {
                    staticClass: "iconfont_Me icon-edit1 iconStyle"
                  })
                ]
              )
            ])
          ]),
          _c("div", { staticClass: "item" }, [
            _c("div", { staticClass: "leftIcon" }, [
              _c("img", {
                style: {
                  width: "20px",
                  height: "20px"
                },
                attrs: {
                  src: require("../../../../assets/images/newSetting/questionIcon.png")
                }
              })
            ]),
            _c("span", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.questionFilled,
                  expression: "questionFilled"
                }
              ],
              staticClass: "iconfont_Me icon-check iconStyle"
            }),
            _c("div", { staticClass: "rightBox" }, [
              _c(
                "div",
                {
                  staticClass: "edit",
                  on: {
                    click: function($event) {
                      return _vm.startSetting(2)
                    }
                  }
                },
                [
                  _c("span", {
                    staticClass: "iconfont_Me icon-edit1 iconStyle"
                  })
                ]
              )
            ])
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }