var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "windowBody" }, [
    _c(
      "div",
      {
        staticClass: "loginAndRegisterCommonContent",
        attrs: { id: "checkAccountWindow" }
      },
      [
        _c("WindowHeader", [_vm._v(_vm._s(_vm.$t("enterTheAccount")))]),
        _c(
          "div",
          { staticClass: "inputOuter" },
          [
            _c(
              "CommonInput",
              {
                attrs: {
                  needInputshowError:
                    _vm.accountStatus === "noSuchAccount" ||
                    _vm.accountStatus === "noSecureInformation"
                },
                on: { inputChange: _vm.getAccountText }
              },
              [
                _c("span", {
                  staticClass: "iconfont_Me icon-user-fill iconStyle"
                })
              ]
            )
          ],
          1
        ),
        _c("div", { staticClass: "errorTips" }, [
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.accountStatus === "noSecureInformation",
                  expression: "accountStatus === 'noSecureInformation'"
                }
              ]
            },
            [_vm._v(_vm._s(_vm.$t("backPwdErrorTip")))]
          )
        ]),
        _c(
          "div",
          {
            staticClass: "verificationButton",
            class: _vm.allowedNextStep ? "commomButtonHover" : "",
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.startNextStep.apply(null, arguments)
              }
            }
          },
          [_c("span", { staticClass: "iconfont_Me icon-check iconStyle" })]
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "windowFooter", style: { marginBottom: "50px" } },
      [
        !_vm.directly_enter_password_recovery_window
          ? _c(
              "div",
              {
                staticClass: "backToPrevious",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.enterTheLoginProcess.apply(null, arguments)
                  }
                }
              },
              [_c("span", { staticClass: "iconfont_Me icon-return iconStyle" })]
            )
          : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }