<template>
  <div class="windowBody">
    <WindowHeader>{{ $t("bind") }}{{ $t("phone") }}</WindowHeader>
    <div class="verifyPhone">
      <VerifyPhone
        @selectThisAreaCode="selectThisAreaCode"
        @getInputText="getNewPhoneNumberText"
        @getVerificationText="verificationCodeForNewPhone"
      ></VerifyPhone>
      <div
        class="nextButton"
        :class="allowedBindPhone ? 'commomButtonHover' : ''"
        @click.stop="startNextStep"
        :style="{
          cursor: allowedBindPhone ? 'pointer' : 'not-allowed',
        }"
      >
        <span class="iconfont_Me icon-check iconStyle"></span>
      </div>
    </div>
  </div>
</template>
<script>
import WindowHeader from "../windowHeader.vue";
import VerifyPhone from "../../../components/verifyPhone/index.vue";
export default {
  name: "BindPhone",
  props: {
    /**
     * 0：默认从注册开始
     * 1：团队成员第一次登录，需要修改密码
     */
    type: {
      type: Number,
      default: 0,
    },
  },
  components: {
    WindowHeader,
    VerifyPhone,
  },
  data() {
    return {
      // 需要绑定的新手机号
      newPhoneNumberText: "",
      // 输入的新手机号验证码文本
      verificationTextForNewPhone: "",
      // 新手机号的区号
      countryCodeForNewPhone: "86",
      // 倒计时定时器对象
      countdownTimer: null,
    };
  },
  computed: {
    register() {
      return this.type === 0
        ? this.$store.state.loginAndRegister.teamDefaultRegister
        : this.$store.state.loginAndRegister.memberDefaultRegister;
    },
    // 允许绑定电话号码
    allowedBindPhone() {
      console.log(
        this.verificationTextForNewPhone,
        this.newPhoneNumberText,
        this.countryCodeForNewPhone
      );
      return (
        this.globalTrim(this.verificationTextForNewPhone) !== "" &&
        this.globalTrim(this.newPhoneNumberText) !== "" &&
        /^[0-9]*$/.test(this.globalTrim(this.newPhoneNumberText)) &&
        this.globalTrim(this.countryCodeForNewPhone) !== ""
      );
    },
  },
  methods: {
    // 获取手机区号
    selectThisAreaCode(areaCode) {
      this.countryCodeForNewPhone = areaCode;
    },
    // 获取新手机号的验证码
    verificationCodeForNewPhone(text) {
      this.verificationTextForNewPhone = text;
    },
    // 获取新手机号文本
    getNewPhoneNumberText(text) {
      this.newPhoneNumberText = text;
    },
    // 验证手机号成功则进入下一步
    async startNextStep() {
      if (this.allowedBindPhone) {
        this.save();
        this.$emit("confirm");
      }
    },
    save() {
      let register = _.cloneDeep(this.register);
      register.phone = this.globalTrim(this.newPhoneNumberText);
      register.phoneCode = this.globalTrim(this.verificationTextForNewPhone);
      register.countryCode = this.countryCodeForNewPhone;
      if (this.type === 0) {
        this.$store.commit("setTeamDefaultRegister", register);
      } else {
        this.$store.commit("setMemberDefaultRegister", register);
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
.verifyPhone
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 40px 0 57px 0;
  .nextButton
    width: 360px;
    height: 46px;
    border-radius: 4px;
    background: rgba(0,0,0,0.10);
    display: flex;
    align-items: center;
    justify-content: center;
    .iconStyle
      font-size: 22px;
      color: #FFF;
</style>
