<template>
  <div class="windowBody">
    <WindowHeader></WindowHeader>
    <div class="format">
      <div class="securityList">
        <div class="tip">{{ $t("pleaseSetSecurity") }}</div>
        <div class="item">
          <div class="leftIcon">
            <img
              :src="
                require('../../../../assets/images/newSetting/phoneIcon.png')
              "
              :style="{
                width: '20px',
                height: '20px',
              }"
            />
          </div>
          <span
            class="iconfont_Me icon-check iconStyle"
            v-show="phoneFilled"
          ></span>
          <div class="rightBox">
            <div class="text" v-show="phoneFilled">{{ phone }}</div>
            <div class="edit" @click="startSetting(0)">
              <span class="iconfont_Me icon-edit1 iconStyle"></span>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="leftIcon">
            <img
              :src="
                require('../../../../assets/images/newSetting/emailIcon.png')
              "
              :style="{
                width: '20px',
                height: '20px',
              }"
            />
          </div>
          <span
            class="iconfont_Me icon-check iconStyle"
            v-show="emailFilled"
          ></span>
          <div class="rightBox">
            <div class="text" v-show="emailFilled">{{ email }}</div>
            <div class="edit" @click="startSetting(1)">
              <span class="iconfont_Me icon-edit1 iconStyle"></span>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="leftIcon">
            <img
              :src="
                require('../../../../assets/images/newSetting/questionIcon.png')
              "
              :style="{
                width: '20px',
                height: '20px',
              }"
            />
          </div>
          <span
            class="iconfont_Me icon-check iconStyle"
            v-show="questionFilled"
          ></span>
          <div class="rightBox">
            <div class="edit" @click="startSetting(2)">
              <span class="iconfont_Me icon-edit1 iconStyle"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import WindowHeader from "../windowHeader.vue";
export default {
  name: "Security",
  props: {
    /**
     * 0：默认从注册开始
     * 1：团队成员第一次登录，需要修改密码
     */
    type: {
      type: Number,
      default: 0,
    },
  },
  components: {
    WindowHeader,
  },
  data() {
    return {
      question: "",
    };
  },
  computed: {
    register() {
      return this.type === 0
        ? this.$store.state.loginAndRegister.teamDefaultRegister
        : this.$store.state.loginAndRegister.memberDefaultRegister;
    },
    phone() {
      return this.register && this.register.phone ? this.register.phone : "";
    },
    email() {
      return this.register && this.register.email ? this.register.email : "";
    },
    questionFilled() {
      return this.register &&
        this.register.answers &&
        this.register.answers.length != 0
        ? true
        : false;
    },
    phoneFilled() {
      return this.phone !== "";
    },
    emailFilled() {
      return this.email !== "";
    },
  },
  methods: {
    startSetting(type) {
      switch (type) {
        case 0:
          if (!this.phoneFilled) {
            this.$emit("nextWindow", "BindPhone");
          }
          break;
        case 1:
          if (!this.emailFilled) {
            this.$emit("nextWindow", "BindEmail");
          }
          break;
        case 2:
          if (!this.questionFilled) {
            this.$emit("nextWindow", "BindQuestion");
          }
          break;
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
.format
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  padding-bottom: 50px;
  .securityList
    width: 100%;
    overflow hidden;
    flex: 1;
    min-height: 0px;
    padding-top: 30px;
    .tip
      width: 100%;
      min-height: 40px;
      background: #F9F9F9;
      line-height: 20px;
      color #919191;
      font-size: 14px;
      text-align: left;
      padding 10px 18px;
      box-sizing: border-box;
      user-select: none;
    .item
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding 18px 0 17px 0;
      border-bottom: 1px solid #F0F0F0;
      margin-top: 7px;
      .leftIcon
        width: 24px;
        height: 24px;
        overflow hidden;
        flex-shrink: 0;
        margin: 0 5px 0 11.5px;
        display: flex;
        align-items: center;
        justify-content: center;
      .iconStyle
        color: #3c6;
        flex-shrink: 0;
      .rightBox
        flex 1;
        height: 24px;
        min-width: 0px;
        box-sizing: border-box;
        padding-right: 11.5px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .text
          flex 1;
          height: 100%;
          min-width: 0px;
          color: #666666;
          text-align: right;
          line-height: 24px;
          box-sizing: border-box;
          padding:0 2px 0 10px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        .edit
          width: 24px;
          height: 100%;
          flex-shrink: 0;
          display: flex;
          cursor pointer;
          align-items: center;
          justify-content: center;
          .iconStyle
            color: #999999;
</style>
