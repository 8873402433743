<template>
  <div class="windowBody">
    <div class="loginAndRegisterCommonContent" id="registerWindow">
      <WindowHeader>{{ $t("register") }}</WindowHeader>
      <div class="inputAreaBox">
        <el-form @submit.stop="() => false">
          <div class="inputOuter">
            <CommonInput
              @inputChange="getAccountText"
              :maxlength="100"
              :defaultInputText="userName"
              :needInputshowError="needInputshowError"
              :checkAccountSuccess="checkAccountSuccess"
            >
              <span class="iconfont_Me icon-user-fill iconStyle"></span>
            </CommonInput>
          </div>
          <div class="inputOuter">
            <CommonInput
              @inputChange="getPasswordText"
              :passwordInput="true"
              :defaultInputText="userPassword"
            >
              <span class="iconfont_Me icon-password-fill iconStyle"></span>
            </CommonInput>
          </div>
        </el-form>
      </div>
      <div class="registerOperationArea">
        <div class="operationWidget">
          <el-checkbox
            class="checkboxStyle"
            v-model="agreeToTheAgreement"
          ></el-checkbox>
          {{ $t("haveReadAndAgree") }}
          &nbsp;
          <span
            class="specialStyle"
            @click.stop.self="enterTheAgreementWindow"
            >{{ $t("userAgreement") }}</span
          >
        </div>
      </div>
      <div
        class="RegisterButton"
        :class="allowedRegister ? 'commomButtonHover' : ''"
        @click.stop="startRegister"
      >
        <span class="iconfont_Me icon-check iconStyle"></span>
      </div>
    </div>
    <div class="windowFooter" :style="{ marginBottom: '50px' }">
      <div class="backToPrevious" @click.stop="enterTheInitialWindow">
        <span class="iconfont_Me icon-return iconStyle"></span>
      </div>
    </div>
  </div>
</template>
<script>
import CommonInput from "../../components/inputComponent/commonInput";
import WindowHeader from "./windowHeader";
import { existsByName } from "@/api/newVersion/loginAndRegister";
export default {
  name: "registerWindow",
  components: {
    CommonInput,
    WindowHeader,
  },
  computed: {
    // 是否可以登录
    allowedRegister() {
      return (
        this.userName !== "" &&
        this.userPassword !== "" &&
        this.agreeToTheAgreement &&
        this.checkAccountSuccess
      );
    },
  },
  data() {
    return {
      // 账号
      userName: "",
      // 密码
      userPassword: "",
      // 账号是否重复验证状态
      checkAccountSuccess: false,
      // 显示错误
      needInputshowError: false,
      // 同意用户协议状态
      agreeToTheAgreement: false,
      // 延迟检测
      timer: null,
    };
  },
  watch: {
    // 监听注册需要默认填充的内容
    "$store.state.loginAndRegister.defaultRegister": {
      async handler(val, old) {
        if (val !== null) {
          for (let key in val) {
            this[key] = val[key];
          }
          await this.getAccountText(this.userName);
          this.$forceUpdate();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  destroyed() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  },
  methods: {
    // 获取账号文本
    getAccountText(inputText) {
      if (inputText !== "") {
        if (this.timer) {
          clearTimeout(this.timer);
        }
        this.timer = setTimeout(async () => {
          let result = await existsByName(inputText);
          if (result.code == 200 && !result.data.data) {
            this.userName = inputText;
            this.checkAccountSuccess = true;
            this.$forceUpdate();
          } else if (result.data.data) {
            this.checkAccountSuccess = false;
          }
          this.needInputshowError = !this.checkAccountSuccess;
        }, 500);
      }
    },
    // 获取密码文本
    getPasswordText(text) {
      this.userPassword = text;
    },
    // 开始注册
    startRegister() {
      if (this.allowedRegister) {
        let register = {
          userName: this.userName,
          userPassword: this.userPassword,
        };
        this.$emit("handlerRegister", register);
      }
    },
    // 点击返回初始窗口
    enterTheInitialWindow() {
      this.$store.commit("setDefaultRegister", null);
      this.$emit("enterTheInitialWindow");
    },
    // 点击前往用户协议窗口
    enterTheAgreementWindow() {
      // 用于从协议窗口回来的记录
      let defaultRegister = {
        userName: this.userName,
        userPassword: this.userPassword,
        agreeToTheAgreement: this.agreeToTheAgreement,
      };
      // 存入状态管理器
      this.$store.commit("setDefaultRegister", defaultRegister);
      this.$nextTick(() => {
        this.$emit("enterTheAgreementWindow");
      });
    },
  },
};
</script>
<style lang="stylus" scoped>
.windowBody
  width 100%;
  height 100%;
  padding 0 45px;
  box-sizing border-box;
  display flex;
  flex-flow column;
  align-items center;
  justify-content space-between;
  .registerOperationArea
    width 100%;
    height 50px;
    display flex;
    align-items center;
    justify-content flex-start;
    .operationWidget
      font-size 14px;
      color #343434;
      user-select none;
      letter-spacing: 1px;
      .checkboxStyle
        margin-right 3px;
      .specialStyle
        color #33CC66;
        cursor pointer;
  .RegisterButton
    width 100%;
    height 46px;
    margin-top 40px;
    border-radius 4px;
    overflow hidden;
    background #E6E6E6;
    display flex;
    align-items center;
    justify-content center;
    cursor not-allowed;
    .iconStyle
      color #FFFFFF;
      font-size 16px;
</style>
