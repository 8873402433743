var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "privacyAgreementBody" },
    _vm._l(_vm.contentArr, function(item, index) {
      return _c("div", { key: index, staticClass: "contentBox" }, [
        _c("div", { staticClass: "contentTitle" }, [
          _vm._v("\n      " + _vm._s(item.title) + "\n    ")
        ]),
        _c(
          "div",
          {
            staticClass: "contentInner",
            style: { "word-break": _vm.languageCode == 1 ? "" : "keep-all" }
          },
          [_vm._v("\n      " + _vm._s(item.content) + "\n    ")]
        )
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }