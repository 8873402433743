<template>
  <div class="windowBody" id="privacyAgreementWindow">
    <div class="windowHeader">
      <div
        class="backToPrevious"
        @click.stop="enterTheRegisterProcess"
        v-show="!directly_enter_privacy_agreement_window"
      >
        <span class="iconfont_Me icon-left iconStyle"></span>
      </div>
      <div class="closeDialog" @click.stop="closeDialog">
        <span class="iconfont_Me icon-x iconStyle"></span>
      </div>
    </div>
    <div class="privacyPolicyBox">
      <PrivacyPolicy></PrivacyPolicy>
    </div>
  </div>
</template>
<script>
import PrivacyPolicy from "../../components/privacyPolicy/privacyPolicy.vue";
export default {
  name: "privacyAgreementWindow",
  components: {
    PrivacyPolicy,
  },
  data() {
    return {};
  },
  computed: {
    // 是否需要快速进入隐私协议入口
    directly_enter_privacy_agreement_window() {
      return this.$store.state.loginAndRegister
        .directly_enter_privacy_agreement_window;
    },
    contentArr() {
      let contentArr = this.$store.getters.privacyContents;
      return contentArr;
    },
  },
  methods: {
    // 关闭弹窗
    closeDialog() {
      this.$emit("closeDialog");
    },
    // 返回注册窗口
    enterTheRegisterProcess() {
      this.$emit("enterTheRegisterProcess");
    },
  },
};
</script>
<style lang="stylus" scoped>
.windowBody {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 20px 0 15px 0;
  box-sizing: border-box;
  flex-flow: column;
  align-items: center;
  justify-content: flex-start;

  .windowHeader {
    height: 24px;
    width: 100%;
    overflow: hidden;
    flex-shrink: 0;
    box-sizing: border-box;
    padding: 0 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .backToPrevious {
      width: 24px;
      height: 24px;
      cursor: pointer;
      border-radius: 50%;
      background: #F0F0F0;
      line-height: 24px;
      text-align: center;
      user-select: none;
      transition: background 0.3s;

      &:hover {
        background: #E8E8E8;
      }

      .iconStyle {
        font-size: 12px;
        color: #000000;
        font-weight: bold;
      }
    }

    .closeDialog {
      width: 24px;
      height: 24px;
      cursor: pointer;
      border-radius: 50%;
      line-height: 24px;
      text-align: center;
      user-select: none;

      .iconStyle {
        font-size: 16px;
        color: #CCCCCC;
        font-weight: bold;
      }
    }
  }
  .privacyPolicyBox{
    flex: 1;
    width: 100%;
    padding: 0 35px;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      width: 0px;
    }
  }
}
</style>
