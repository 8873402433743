<template>
  <div class="windowBody">
    <WindowHeader>{{ $t("setNewPwd") }}</WindowHeader>
    <div class="format">
      <div
        class="tip"
        :style="{
          color: firstInputShowError ? '#F57575' : '#8F8F8F',
        }"
      >
        {{ $t("setPasswordTips") }}
      </div>
      <div class="top">
        <div class="formBox">
          <el-form @submit.stop="() => false">
            <div class="inputOuter">
              <CommonInput
                @inputChange="getFirstPassword"
                :passwordInput="true"
                :defaultInputText="firstPassword"
                :needInputshowError="firstInputShowError"
              >
                <span class="iconfont_Me icon-a-lujing13297 iconStyle"></span>
              </CommonInput>
            </div>
            <div class="inputOuter" style="margin-bottom:0">
              <CommonInput
                @inputChange="getSecondPassword"
                :passwordInput="true"
                :defaultInputText="secondPassword"
                :needInputshowError="passwordNotTheSame"
              >
                <span class="iconfont_Me icon-a-zu9564 iconStyle"></span>
              </CommonInput>
            </div>
            <div
              class="inputErrorTips"
              :style="{ opacity: passwordNotTheSame ? 1 : 0 }"
            >
              {{ $t("pwdNotSame") }}
            </div>
          </el-form>
        </div>
      </div>
      <div
        class="registerButton"
        :class="allowedRegister ? 'commomButtonHover' : ''"
        @click.stop="startRegister"
        :style="{
          cursor: allowedRegister ? 'pointer' : 'not-allowed',
        }"
      >
        <span class="iconfont_Me icon-tubiaozhizuomoban iconStyle"></span>
      </div>
    </div>
  </div>
</template>
<script>
import WindowHeader from "../windowHeader.vue";
import CommonInput from "../../../components/inputComponent/commonInput.vue";
export default {
  name: "MemberRegister",
  components: {
    WindowHeader,
    CommonInput,
  },
  data() {
    return {
      // 第一次输入的密码
      firstPassword: "",
      // 第二次输入的密码
      secondPassword: "",
      // 第一个输入密码框显示错误提示
      firstInputShowError: false,
      // 延迟检测
      timer: null,
    };
  },
  computed: {
    // 验证密码是否符号规则的正则表达式
    verificationRegExp() {
      return new RegExp(/^(?![^a-zA-Z]+$)(?!\D+$)(?![a-zA-Z0-9]+$).{8,}$/);
    },
    allowedRegister() {
      return (
        this.firstPassword !== "" &&
        this.secondPassword !== "" &&
        !this.firstInputShowError &&
        !this.passwordNotTheSame
      );
    },
    passwordNotTheSame() {
      return (
        this.firstPassword !== "" &&
        this.secondPassword !== "" &&
        this.firstPassword !== this.secondPassword
      );
    },
  },
  watch: {
    // 监听注册需要默认填充的内容
    "$store.state.loginAndRegister.memberDefaultRegister": {
      async handler(val, old) {
        if (val !== null) {
          const arr = ["firstPassword", "secondPassword"];
          for (let i = 0; i < arr.length; i++) {
            this[arr[i]] = val[arr[i]];
          }
          this.$forceUpdate();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    // 获取密码文本
    getFirstPassword(text) {
      this.firstPassword = this.globalTrim(text);
      this.firstInputShowError = !this.verificationRegExp.test(
        this.firstPassword
      );
    },
    // 获取密码文本
    getSecondPassword(text) {
      this.secondPassword = this.globalTrim(text);
    },
    startRegister() {
      if (this.allowedRegister) {
        this.save();
        this.$emit("nextWindow", "Security");
      }
    },
    save() {
      let memberDefaultRegister = {
        firstPassword: this.firstPassword,
        secondPassword: this.secondPassword,
      };
      // 存入状态管理器
      this.$store.commit("setMemberDefaultRegister", memberDefaultRegister);
    },
  },
};
</script>
<style lang="stylus" scoped>
.format
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  padding-bottom: 49px;
  .tip
    font-size: 13px;
  .top
    width: 100%;
    flex 1;
    overflow hidden;
    box-sizing: border-box;
    .formBox
      width: 100%;
      overflow hidden;
      box-sizing: border-box;
      padding-top 26px;
      .inputOuter
        width 100%;
        height 46px;
        overflow hidden;
        margin-bottom 18px;
        .iconStyle
          color #666666;
          font-size 16px;
      .inputErrorTips
        width 100%;
        overflow hidden;
        color #FB0000;
        font-size 12px;
        text-align left;
    .registerOperationArea
      width 100%;
      overflow hidden;
      margin-top: 2px;
      display flex;
      align-items center;
      justify-content flex-start;
      .operationWidget
        font-size 14px;
        color #343434;
        user-select none;
        letter-spacing: 1px;
        .checkboxStyle
          margin-right 3px;
        .specialStyle
          color #33CC66;
          cursor pointer;
  .registerButton
    width: 360px;
    height: 46px;
    border-radius: 4px;
    background: rgba(0,0,0,0.10);
    display: flex;
    align-items: center;
    justify-content: center;
    .iconStyle
      font-size: 22px;
      color: #FFF;
</style>
