<template>
  <div class="windowBody">
    <WindowHeader>{{ $t("bind") }}{{ $t("secureQuestion") }}</WindowHeader>
    <div class="format">
      <div class="questions">
        <div
          class="inputOuterStyle"
          v-for="(item, index) in setUpSecurityQuestions"
          :key="index"
          :style="{
            'padding-top': item.showSelecter ? '9px' : '0px',
          }"
        >
          <QuestionListPopover
            :handlerQuestionsList="listOfQuestions"
            v-if="item.showSelecter"
            :selecterIndex="index"
            @selectQuestion="selectQuestion"
          >
            <div class="questionSelecter">
              <div class="selecterLeft">
                <div class="questionIcon">
                  <el-image
                    style="width: 18px; height: 18px"
                    :src="
                      require('../../../../assets/images/newLogin/questionIconLarge.png')
                    "
                    fit="fill"
                  ></el-image>
                </div>
                <div class="questionText">
                  {{ $t("setAnswer") }}
                </div>
              </div>
              <div class="selecterRight">
                <span class="iconfont_Me icon-down iconStyle"></span>
              </div>
            </div>
          </QuestionListPopover>
          <QAInput
            v-if="!item.showSelecter"
            :needCloseQuestion="true"
            :questionItem="item.secureQA"
            :autoFocus="!item.showSelecter"
            :indexInParent="index"
            @getAnswer="getNewAnswer"
            @closeQuestionBox="closeQuestionBox"
          ></QAInput>
        </div>
      </div>
      <div
        class="nextButton"
        :class="allowedSetUpNewQA ? 'commomButtonHover' : ''"
        @click.stop="startNextStep"
        :style="{
          cursor: allowedSetUpNewQA ? 'pointer' : 'not-allowed',
        }"
      >
        <span class="iconfont_Me icon-check iconStyle"></span>
      </div>
    </div>
  </div>
</template>
<script>
import WindowHeader from "../windowHeader.vue";
import { getSecuresQuestions } from "@/newVersion/components/accountSecurityDialog/api";
import QAInput from "../../../components/inputComponent/QAInput";
import QuestionListPopover from "../../../components/questionListPopover";
export default {
  name: "BindQuestion",
  props: {
    /**
     * 0：默认从注册开始
     * 1：团队成员第一次登录，需要修改密码
     */
    type: {
      type: Number,
      default: 0,
    },
  },
  components: {
    WindowHeader,
    QAInput,
    QuestionListPopover,
  },
  data() {
    return {
      // 全部问题列表
      listOfQuestions: [],
      // 设置安全问答的列表
      setUpSecurityQuestions: [
        {
          secureQA: {},
          showSelecter: true,
        },
        {
          secureQA: {},
          showSelecter: true,
        },
        {
          secureQA: {},
          showSelecter: true,
        },
      ],
    };
  },
  async created() {
    if (this.questionAnswers.length === 0) {
      await this.handlerGetQuestiongList();
    }
    this.listOfQuestions = this.questionAnswers;
  },
  computed: {
    // 验证是否填写完成新的QA
    allowedSetUpNewQA() {
      return this.setUpSecurityQuestions.every(
        (item) => item.secureQA.answer && item.secureQA.answer !== ""
      );
    },
    register() {
      return this.type === 0
        ? this.$store.state.loginAndRegister.teamDefaultRegister
        : this.$store.state.loginAndRegister.memberDefaultRegister;
    },
    teamDefaultRegister() {
      return this.$store.state.loginAndRegister.teamDefaultRegister;
    },
    memberDefaultRegister() {
      return this.$store.state.loginAndRegister.memberDefaultRegister;
    },
    questionAnswers() {
      return this.$store.state.accountStore.secureInfo.allSecureQuestionAnswers
        ? _.cloneDeep(
            this.$store.state.accountStore.secureInfo.allSecureQuestionAnswers
          )
        : [];
    },
  },
  methods: {
    // 获取系统安全问题集合
    handlerGetQuestiongList() {
      return new Promise(async (resolve, reject) => {
        const result = await getSecuresQuestions();
        if (result && result.code === 200) {
          let secureInfo = _.cloneDeep(
            this.$store.state.accountStore.secureInfo
          );
          secureInfo.allSecureQuestionAnswers =
            result.data.data.filter((item) => !item.deleted) || [];
          this.$store.commit("setSecureInfo", secureInfo);
        }
        resolve("success");
      });
    },
    // 选择当前问题
    selectQuestion(questionItem, index) {
      this.setUpSecurityQuestions[index].secureQA = {
        answer: "",
        providerId: "0",
        question: questionItem.question,
        questionId: questionItem.id,
      };
      this.setUpSecurityQuestions[index].showSelecter = false;
      this.$forceUpdate();
      this.handlerQuestionsList();
    },
    // 获取新设置的答案
    getNewAnswer(answerItem, index) {
      this.setUpSecurityQuestions[index].secureQA = answerItem;
      this.$forceUpdate();
    },
    // 关闭问题，重新选择问题
    closeQuestionBox(index) {
      this.setUpSecurityQuestions[index].secureQA = {};
      this.setUpSecurityQuestions[index].showSelecter = true;
      this.$forceUpdate();
      this.handlerQuestionsList();
    },
    // 每次操作问题后，处理问题列表的数据结构
    handlerQuestionsList() {
      // 同步刷新问题列表中被禁用的选项
      this.$nextTick(() => {
        let handlerQuestionsList = _.cloneDeep(this.listOfQuestions);
        // 先全部重置为未选中
        handlerQuestionsList = handlerQuestionsList.map((element) => {
          element.hasSelected = false;
          return element;
        });
        handlerQuestionsList = handlerQuestionsList.map((element) => {
          this.setUpSecurityQuestions.forEach((item) => {
            if (
              item.secureQA.questionId &&
              element.id === item.secureQA.questionId
            ) {
              element.hasSelected = true;
            }
          });
          return element;
        });
        this.listOfQuestions = handlerQuestionsList;
        this.$forceUpdate();
      });
    },
    startNextStep() {
      if (!this.allowedSetUpNewQA) {
        return;
      }
      this.save();
      this.$emit("confirm");
    },
    save() {
      console.log("存储");
      let register = _.cloneDeep(this.register);
      register.answers = this.setUpSecurityQuestions.map(
        (item) => item.secureQA
      );
      if (this.type === 0) {
        this.$store.commit("setTeamDefaultRegister", register);
      } else {
        this.$store.commit("setMemberDefaultRegister", register);
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
.format
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  padding-bottom: 50px;
  .questions
    width 100%;
    overflow hidden;
    padding-top 31px;
    .inputOuterStyle
      margin-bottom 11px;
      .questionSelecter
        width 100%;
        height 50px;
        border-radius 4px;
        border 1px solid #8F8F8F;
        cursor pointer;
        user-select none;
        box-sizing border-box;
        padding 0 18px;
        display flex;
        align-items center;
        justify-content flex-start;
        .selecterLeft
          flex 1;
          min-width 0;
          height 50px;
          display flex;
          align-items center;
          justify-content flex-start;
          .questionIcon
            width 18px;
            height 18px;
            flex-shrink 0;
          .questionText
            flex 1;
            height 18px;
            box-sizing border-box;
            padding-left 10px;
            overflow hidden;
            text-overflow ellipsis;
            white-space: nowrap;
            font-size 14px;
            color #CCCCCC;
            user-select none;
        .selecterRight
          width 16px;
          height 100%;
          flex-shrink 0;
          display flex;
          align-items center;
          justify-content center;
          .iconStyle
            font-size 14px;
            color #CCCCCC;
  .nextButton
    width: 360px;
    height: 46px;
    border-radius: 4px;
    background: rgba(0,0,0,0.10);
    display: flex;
    align-items: center;
    justify-content: center;
    .iconStyle
      font-size: 22px;
      color: #FFF;
</style>
