<template>
  <div class="windowBody">
    <div class="loginAndRegisterCommonContent" id="checkAccountWindow">
      <WindowHeader>{{ $t("enterTheAccount") }}</WindowHeader>
      <div class="inputOuter">
        <CommonInput
          @inputChange="getAccountText"
          :needInputshowError="
            accountStatus === 'noSuchAccount' ||
              accountStatus === 'noSecureInformation'
          "
        >
          <span class="iconfont_Me icon-user-fill iconStyle"></span>
        </CommonInput>
      </div>
      <div class="errorTips">
        <span v-show="accountStatus === 'noSecureInformation'">{{
          $t("backPwdErrorTip")
        }}</span>
      </div>
      <div
        class="verificationButton"
        :class="allowedNextStep ? 'commomButtonHover' : ''"
        @click.stop="startNextStep"
      >
        <span class="iconfont_Me icon-check iconStyle"></span>
      </div>
    </div>
    <div class="windowFooter" :style="{ marginBottom: '50px' }">
      <div
        class="backToPrevious"
        @click.stop="enterTheLoginProcess"
        v-if="!directly_enter_password_recovery_window"
      >
        <span class="iconfont_Me icon-return iconStyle"></span>
      </div>
    </div>
  </div>
</template>
<script>
import CommonInput from "../../components/inputComponent/commonInput";
import WindowHeader from "./windowHeader";
import { getEmailInfoByAddress } from "@/api/newVersion/melinked_email";
import { checkEmailAvailable } from "@/api/newVersion/melinked_email";
import isEmail from "validator/lib/isEmail";
export default {
  name: "checkAccount",
  components: {
    CommonInput,
    WindowHeader,
  },
  data() {
    return {
      // 验证状态(当前账号没有设置过安全信息，无法进入下一步: noSecureInformation ; 没有找到当前账号: noSuchAccount)
      accountStatus: "",
      // 延迟验证
      timer: null,
    };
  },
  computed: {
    // 验证通过，可以进入下一步
    allowedNextStep() {
      return this.accountStatus === "success";
    },
    // 是否需要快速进入忘记密码入口
    directly_enter_password_recovery_window() {
      return this.$store.state.loginAndRegister
        .directly_enter_password_recovery_window;
    },
    // 调用来源
    dialogSourceType() {
      return this.$store.state.loginAndRegister.dialogSourceType;
    },
  },
  destroyed() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  },
  methods: {
    // 获取账号文本
    async getAccountText(text) {
      if (text !== "") {
        if (this.timer) {
          clearTimeout(this.timer);
        }
        this.timer = setTimeout(async () => {
          if (this.dialogSourceType == "email") {
            try {
              text = await this.verifyEmailAddress(text);
            } catch (error) {
              return;
            }
          }
          this.$store
            .dispatch("commitSetSecureInfo", text)
            .then((secureInfo) => {
              if (
                !secureInfo.hasSecureQuestion &&
                secureInfo.secureEmail === "" &&
                secureInfo.securePhone === ""
              ) {
                this.accountStatus = "noSecureInformation";
              } else {
                // 如果设置过安全信息，则将安全信息存入状态管理器
                let accountSecureInfomation = {};
                let secureMethodCount = 0;
                // 先清空一次状态管理器中的信息
                this.$store.commit("setAccountSecureInfomation", {});
                this.$store.commit("setSecureMethodCount", 0);
                this.$store.commit("setAccountSecureProviderId", "");
                if (secureInfo.hasSecureQuestion) {
                  secureMethodCount++;
                  accountSecureInfomation.secureQuestionAnswers =
                    secureInfo.secureQuestionAnswers;
                }
                if (secureInfo.secureEmail !== "") {
                  secureMethodCount++;
                  accountSecureInfomation.secureEmail = secureInfo.secureEmail;
                }
                if (secureInfo.securePhone !== "") {
                  secureMethodCount++;
                  accountSecureInfomation.securePhone = secureInfo.securePhone;
                }
                // 记录一共有多少种找回方式
                this.$store.commit("setSecureMethodCount", secureMethodCount);
                this.$store.commit(
                  "setAccountSecureProviderId",
                  secureInfo.providerId
                );
                // 记录找回方式的详细信息
                this.$store.commit(
                  "setAccountSecureInfomation",
                  accountSecureInfomation
                );
                this.accountStatus = "success";
              }
            })
            .catch((code, message) => {
              if (code === 400) {
                this.accountStatus = "noSuchAccount";
              } else {
                this.$message({
                  type: "error",
                  message: message,
                });
                this.accountStatus = "";
              }
            });
        }, 800);
      } else {
        this.accountStatus = "";
      }
    },
    // 验证邮箱地址
    async verifyEmailAddress(text) {
      return new Promise(async (resolve, reject) => {
        let handleText = text;
        if (!isEmail(handleText)) {
          this.$message({
            type: "error",
            message: this.$t("emailAddressIsIncorrect"),
          });
          reject();
          return;
        }
        let result = await checkEmailAvailable(handleText);
        if (result.code == 200) {
          if (result.data.data) {
            const infoResult = await getEmailInfoByAddress(handleText, {
              cancelPermissions: true,
            });
            if (infoResult.code == 200) {
              resolve(infoResult.data.data.ucId);
            } else {
              this.$message({
                type: "error",
                message: infoResult.message,
              });
              this.accountStatus = "noSuchAccount";
              reject();
            }
          } else {
            this.accountStatus = "noSuchAccount";
            this.$message({
              type: "error",
              message: this.$t("checkEmailAvailableTips"),
            });
            reject();
          }
        } else {
          this.$message({
            type: "error",
            message: result.message,
          });
          reject();
        }
      });
    },
    // 点击返回登录窗口
    enterTheLoginProcess() {
      this.$emit("enterTheLoginProcess");
    },
    // 验证成功进入下一步
    startNextStep() {
      if (this.allowedNextStep) {
        this.$emit("enterTheRetrieveMethodProcess");
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
.windowBody
  width 100%;
  height 100%;
  padding 0 45px;
  box-sizing border-box;
  display flex;
  flex-flow column;
  align-items center;
  justify-content space-between;
  .inputOuter
    width 100%;
    height 46px;
    margin-top 50px;
  .errorTips
    margin-top 18px;
    min-height 15px;
    line-height 15px;
    font-size 12px;
    color #FB0000;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  .verificationButton
    width 100%;
    height 46px;
    margin 50px 0;
    border-radius 4px;
    overflow hidden;
    background #E6E6E6;
    display flex;
    align-items center;
    justify-content center;
    cursor not-allowed;
    .iconStyle
      color #FFFFFF;
      font-size 16px;
</style>
