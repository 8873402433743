var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "windowBody" },
    [
      _c("WindowHeader", [_vm._v(_vm._s(_vm.$t("teamRegistration")))]),
      _c("div", { staticClass: "format" }, [
        _c(
          "div",
          {
            staticClass: "tip",
            style: {
              color: "#F57575"
            }
          },
          [_vm._v("\n      " + _vm._s(_vm.$t("setPasswordTips")) + "\n    ")]
        ),
        _c("div", { staticClass: "top" }, [
          _c(
            "div",
            { staticClass: "formBox" },
            [
              _c(
                "el-form",
                {
                  on: {
                    submit: function($event) {
                      $event.stopPropagation()
                      return (() => false).apply(null, arguments)
                    }
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "inputOuter" },
                    [
                      _c(
                        "CommonInput",
                        {
                          attrs: {
                            placeholderText: _vm.$t("teamAdministrator"),
                            maxlength: 100,
                            defaultInputText: _vm.userName,
                            needInputshowError: _vm.needInputshowError,
                            checkAccountSuccess: _vm.checkAccountSuccess
                          },
                          on: { inputChange: _vm.getAccountText }
                        },
                        [
                          _c("span", {
                            staticClass: "iconfont_Me icon-user-fill iconStyle"
                          })
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "inputOuter" },
                    [
                      _c(
                        "CommonInput",
                        {
                          attrs: {
                            passwordInput: true,
                            defaultInputText: _vm.firstPassword,
                            needInputshowError: _vm.firstInputShowError
                          },
                          on: { inputChange: _vm.getFirstPassword }
                        },
                        [
                          _c("span", {
                            staticClass:
                              "iconfont_Me icon-a-lujing13297 iconStyle"
                          })
                        ]
                      )
                    ],
                    1
                  )
                ]
              )
            ],
            1
          ),
          _c("div", { staticClass: "registerOperationArea" }, [
            _c(
              "div",
              { staticClass: "operationWidget" },
              [
                _c("el-checkbox", {
                  staticClass: "checkboxStyle",
                  model: {
                    value: _vm.agreeToTheAgreement,
                    callback: function($$v) {
                      _vm.agreeToTheAgreement = $$v
                    },
                    expression: "agreeToTheAgreement"
                  }
                }),
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("haveReadAndAgree")) +
                    "\n           \n          "
                ),
                _c(
                  "span",
                  {
                    staticClass: "specialStyle",
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        if ($event.target !== $event.currentTarget) return null
                        return _vm.enterTheAgreementWindow.apply(
                          null,
                          arguments
                        )
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("userAgreement")))]
                )
              ],
              1
            )
          ])
        ]),
        _c(
          "div",
          {
            staticClass: "registerButton",
            class: _vm.allowedRegister ? "commomButtonHover" : "",
            style: {
              cursor: _vm.allowedRegister ? "pointer" : "not-allowed"
            },
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.startRegister.apply(null, arguments)
              }
            }
          },
          [
            _c("span", {
              staticClass: "iconfont_Me icon-tubiaozhizuomoban iconStyle"
            })
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }