var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "windowBody" }, [
    _c(
      "div",
      {
        staticClass: "loginAndRegisterCommonContent",
        attrs: { id: "verifyQAWindow" }
      },
      [
        _c("WindowHeader", [
          _vm._v(
            _vm._s(_vm.$t("verification")) + _vm._s(_vm.$t("secureQuestion"))
          )
        ]),
        _c(
          "div",
          { staticClass: "inputAreaBox" },
          _vm._l(
            _vm.$store.state.loginAndRegister.accountSecureInfomation
              .secureQuestionAnswers,
            function(item) {
              return _c(
                "div",
                { key: item.questionId, staticClass: "inputOuterStyle" },
                [
                  _c("QAInput", {
                    attrs: { questionItem: item },
                    on: { getAnswer: _vm.getAnswer }
                  })
                ],
                1
              )
            }
          ),
          0
        ),
        _c(
          "div",
          {
            staticClass: "nextStepButton",
            class: _vm.allowedNextStep ? "commomButtonHover" : "",
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.startNextStep.apply(null, arguments)
              }
            }
          },
          [_c("span", { staticClass: "iconfont_Me icon-check iconStyle" })]
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "windowFooter", style: { marginBottom: "50px" } },
      [
        _c(
          "div",
          {
            staticClass: "backToPrevious",
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.goBackToThePreviousStep.apply(null, arguments)
              }
            }
          },
          [_c("span", { staticClass: "iconfont_Me icon-return iconStyle" })]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }