var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "windowBody" }, [
    _c(
      "div",
      {
        staticClass: "loginAndRegisterCommonContent",
        attrs: { id: "loginWindow" }
      },
      [
        _c("WindowHeader", [_vm._v(_vm._s(_vm.$t("login")))]),
        _c(
          "div",
          { staticClass: "inputAreaBox" },
          [
            _c(
              "el-form",
              {
                on: {
                  submit: function($event) {
                    $event.stopPropagation()
                    return (() => false).apply(null, arguments)
                  }
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "inputOuter" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { prop: "userName" } },
                      [
                        _c(
                          "CommonInput",
                          { on: { inputChange: _vm.getAccountText } },
                          [
                            _c("span", {
                              staticClass:
                                "iconfont_Me icon-user-fill iconStyle"
                            })
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "inputOuter" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { prop: "userPassword" } },
                      [
                        _c(
                          "CommonInput",
                          {
                            attrs: { passwordInput: true },
                            on: { inputChange: _vm.getPasswordText }
                          },
                          [
                            _c("span", {
                              staticClass:
                                "iconfont_Me icon-password-fill iconStyle"
                            })
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            )
          ],
          1
        ),
        _c("div", { staticClass: "loginOperationArea" }, [
          _c(
            "div",
            {
              staticClass: "operationWidget",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.enterTheForgetPwdProcess.apply(null, arguments)
                }
              }
            },
            [_vm._v("\n        " + _vm._s(_vm.$t("forgetPwd")) + "\n      ")]
          )
        ]),
        _c(
          "div",
          {
            staticClass: "loginButton",
            class: _vm.allowedLogin ? "commomButtonHover" : "",
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.startLogin.apply(null, arguments)
              }
            }
          },
          [_c("span", { staticClass: "iconfont_Me icon-check iconStyle" })]
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "windowFooter", style: { marginBottom: "50px" } },
      [
        _c(
          "div",
          {
            staticClass: "backToPrevious",
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.enterTheInitialWindow.apply(null, arguments)
              }
            }
          },
          [_c("span", { staticClass: "iconfont_Me icon-return iconStyle" })]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }