<template>
  <el-dialog
    center
    :append-to-body="false"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    :visible.sync="$store.state.loginAndRegister.loginAndRegisterDialog"
    :custom-class="`loginAndRegisterStyle_520`"
  >
    <template v-if="!privacyAgreementWindowFlag">
      <div class="dialogHeader" v-if="!teamWindowFlag">
        <span
          class="iconfont_Me icon-x iconStyle"
          @click.stop="closeDialog"
        ></span>
      </div>
      <div
        class="dialogBody"
        v-loading="isloading"
        element-loading-spinner="el-icon-loading"
        element-loading-background="#FFFFFF"
        :style="{
          height: teamWindowFlag ? '100%' : '453px',
        }"
      >
        <!-- 初始展示的选择注册或登录窗口 -->
        <transition name="transitionStyle">
          <InitialWindow
            v-if="initialWindowFlag && !teamWindowFlag"
            @enterTheLoginProcess="enterTheLoginProcess"
            @enterTheRegisterProcess="enterTheRegisterProcess"
            @selectOtherLoginType="selectOtherLoginType"
            @enterTheTourist="enterTheTourist"
            @teamRegister="teamRegister"
          ></InitialWindow>
        </transition>
        <!-- 注册窗口 -->
        <transition name="transitionStyle">
          <RegisterWindow
            v-if="registerWindowFlag"
            @handlerRegister="handlerRegister"
            @enterTheInitialWindow="enterTheInitialWindow"
            @enterTheAgreementWindow="enterTheAgreementWindow"
          >
          </RegisterWindow>
        </transition>
        <!-- 登录窗口 -->
        <transition name="transitionStyle">
          <LoginWindow
            v-if="loginWindowFlag"
            @handleLogin="handleLogin"
            @enterTheInitialWindow="enterTheInitialWindow"
            @enterTheRegisterProcess="enterTheRegisterProcess"
            @enterTheForgetPwdProcess="enterTheForgetPwdProcess"
          >
          </LoginWindow>
        </transition>
        <!-- 忘记密码验证账号窗口 -->
        <transition name="transitionStyle">
          <CheckAccountWindow
            v-if="checkAccountWindowFlag"
            @enterTheLoginProcess="enterTheLoginProcess"
            @enterTheRetrieveMethodProcess="enterTheRetrieveMethodProcess"
          ></CheckAccountWindow>
        </transition>
        <!-- 忘记密码找回方式的选择窗口 -->
        <transition name="transitionStyle">
          <RetrieveMethodWindow
            v-if="retrieveMethodWindowFlag"
            :retrieveMethodWindowFlag="retrieveMethodWindowFlag"
            @enterTheForgetPwdProcess="enterTheForgetPwdProcess"
            @enterTheSelectedMethod="enterTheSelectedMethod"
          >
          </RetrieveMethodWindow>
        </transition>
        <!-- 忘记密码验证手机号窗口 -->
        <transition name="transitionStyle">
          <VerifyMobilePhoneWindow
            v-if="verifyMobilePhoneWindowFlag"
            @enterTheForgetPwdProcess="enterTheForgetPwdProcess"
            @enterTheRetrieveMethodProcess="enterTheRetrieveMethodProcess"
            @enterTheSetNewPasswordProcess="enterTheSetNewPasswordProcess"
          ></VerifyMobilePhoneWindow>
        </transition>
        <!-- 忘记密码验证邮箱窗口 -->
        <transition name="transitionStyle">
          <VerifyEmailWindow
            v-if="verifyEmailWindowFlag"
            @enterTheForgetPwdProcess="enterTheForgetPwdProcess"
            @enterTheRetrieveMethodProcess="enterTheRetrieveMethodProcess"
            @enterTheSetNewPasswordProcess="enterTheSetNewPasswordProcess"
          ></VerifyEmailWindow>
        </transition>
        <!-- 忘记密码验证安全问答窗口 -->
        <transition name="transitionStyle">
          <VerifyQAWindow
            v-if="verifyQAWindowFlag"
            @enterTheForgetPwdProcess="enterTheForgetPwdProcess"
            @enterTheRetrieveMethodProcess="enterTheRetrieveMethodProcess"
            @enterTheSetNewPasswordProcess="enterTheSetNewPasswordProcess"
          ></VerifyQAWindow>
        </transition>
        <!-- 设置新密码窗口 -->
        <transition name="transitionStyle">
          <SetNewPasswordWindow
            v-if="setNewPasswordWindowFlag"
            @setNewPassword="setNewPassword"
            @enterTheSelectedMethod="enterTheSelectedMethod"
          ></SetNewPasswordWindow>
        </transition>
        <!-- 三方登录用户首次设置新密码窗口 -->
        <transition name="transitionStyle">
          <SetNewPasswordForThird
            v-if="setNewPasswordForThirdFlag"
          ></SetNewPasswordForThird>
        </transition>
        <!-- 团队管理说明窗口teamWindow -->
        <transition name="transitionStyle">
          <teamWindow
            v-if="teamWindowFlag"
            @enterTheInitialWindow="enterTheInitialWindow"
            @handleLogin="handleLogin"
            @closeDialog="closeDialog"
          >
          </teamWindow>
        </transition>
      </div>
    </template>
    <transition name="transitionStyle">
      <!-- 用户隐私窗口 -->
      <PrivacyAgreementWindow
        v-if="privacyAgreementWindowFlag"
        @closeDialog="closeDialog"
        @enterTheRegisterProcess="enterTheRegisterProcess"
      ></PrivacyAgreementWindow>
    </transition>
  </el-dialog>
</template>
<script>
import InitialWindow from "./initialWindow";
import LoginWindow from "./loginWindow";
import RegisterWindow from "./registerWindow";
import CheckAccountWindow from "./checkAccountWindow";
import SetNewPasswordWindow from "./setNewPasswordWindow";
import SetNewPasswordForThird from "./setNewPasswordForThird";
import RetrieveMethodWindow from "./retrieveMethodWindow";
import PrivacyAgreementWindow from "./privacyAgreementWindow";
import VerifyMobilePhoneWindow from "./verifyMobilePhoneWindow";
import VerifyEmailWindow from "./verifyEmailWindow";
import VerifyQAWindow from "./verifyQAWindow";
import teamWindow from "./team/mainWindow.vue";
import hello from "hellojs/dist/hello.all.js";
import { registerNew } from "@/api/newVersion/loginAndRegister";
import {
  addOrUpdatePgpKey,
  currentLoginVipStatus,
  resetLoginPassword,
} from "@/api/newVersion/vip";
import { createPGPKey } from "@/utils/pgp";
export default {
  name: "loginAndRegister",
  components: {
    InitialWindow,
    LoginWindow,
    RegisterWindow,
    CheckAccountWindow,
    SetNewPasswordWindow,
    SetNewPasswordForThird,
    RetrieveMethodWindow,
    PrivacyAgreementWindow,
    VerifyMobilePhoneWindow,
    VerifyEmailWindow,
    VerifyQAWindow,
    teamWindow,
  },
  data() {
    return {
      // 初始登录注册选择模块状态
      initialWindowFlag: false,
      // 登录窗口模块状态
      loginWindowFlag: false,
      // 注册窗口模块状态
      registerWindowFlag: false,
      // 忘记密码验证账号模块状态
      checkAccountWindowFlag: false,
      // 忘记密码选择找回方式模块状态
      retrieveMethodWindowFlag: false,
      // 忘记密码通过验证手机设置新密码模块状态
      verifyMobilePhoneWindowFlag: false,
      // 忘记密码通过验证邮箱设置新密码模块状态
      verifyEmailWindowFlag: false,
      // 忘记密码通过验证安全问答设置新密码模块状态
      verifyQAWindowFlag: false,
      // 设置新密码模块状态
      setNewPasswordWindowFlag: false,
      // 三方登录用户设置新密码模块状态
      setNewPasswordForThirdFlag: false,
      // 隐私条款模块状态
      privacyAgreementWindowFlag: false,
      // 弹窗加载状态
      isloading: false,
    };
  },
  mounted() {
    // 是否是第三方登录用户设置新密码(如果是的话，直接进入设置新密码窗口)
    if (this.directly_enter_password_recovery_window) {
      this.checkAccountWindowFlag = true;
      return;
    }
    if (this.directly_enter_privacy_agreement_window) {
      this.privacyAgreementWindowFlag = true;
      return;
    }
    if (this.directly_enter_team_register_window) {
      this.$store.commit("setTeamWindowFlag", true);
      return;
    }
    if (this.$store.state.loginAndRegister.isThirdAccountSetNewPassword) {
      this.setNewPasswordForThirdFlag = true;
    } else {
      this.initialWindowFlag = true;
    }
    hello.init(
      {
        twitter: "NjnU0lG7BZNOvf1wgjlXfxJPA",
        facebook: "2996347000381985",
      },
      {
        oauth_proxy: "https://auth-server.herokuapp.com/proxy",
      }
    );
  },
  beforeDestroy() {
    localStorage.removeItem("joinMembership");
    this.$store.commit("setLoginAndRegisterDialogSourceType", "default");
    this.$store.commit("setDirectly_enter_password_recovery_window", false);
    this.$store.commit("setDirectly_enter_privacy_agreement_window", false);
    this.$store.commit("setDirectly_enter_team_register_window", false);
  },
  computed: {
    teamWindowFlag() {
      return this.$store.state.team.teamWindowFlag;
    },
    // 验证密码是否符号规则的正则表达式
    verificationRegExp() {
      return new RegExp(/^(?![^a-zA-Z]+$)(?!\D+$)(?![a-zA-Z0-9]+$).{8,}$/);
    },
    // 是否需要快速进入忘记密码入口
    directly_enter_password_recovery_window() {
      return this.$store.state.loginAndRegister
        .directly_enter_password_recovery_window;
    },
    // 是否需要快速进入隐私协议入口
    directly_enter_privacy_agreement_window() {
      return this.$store.state.loginAndRegister
        .directly_enter_privacy_agreement_window;
    },
    // 是否需要快速进入团队注册
    directly_enter_team_register_window() {
      return this.$store.state.loginAndRegister
        .directly_enter_team_register_window;
    },
  },
  methods: {
    // 关闭登录注册窗口
    closeDialog() {
      this.$store.dispatch("commitCloseLoginAndRegisterDialog");
    },
    // 点击进入初始界面
    enterTheInitialWindow() {
      Object.assign(this.$data, this.$options.data.call(this));
      this.$store.commit("setTeamWindowFlag", false);
      this.$forceUpdate();
      this.initialWindowFlag = true;
    },
    // 点击进入登录流程
    enterTheLoginProcess() {
      Object.assign(this.$data, this.$options.data.call(this));
      this.$forceUpdate();
      this.loginWindowFlag = true;
    },
    // 点击进入忘记密码流程，先进入验证账号窗口
    enterTheForgetPwdProcess() {
      Object.assign(this.$data, this.$options.data.call(this));
      this.$forceUpdate();
      this.checkAccountWindowFlag = true;
    },
    // 点击进入找回密码选择找回方式窗口
    enterTheRetrieveMethodProcess() {
      Object.assign(this.$data, this.$options.data.call(this));
      this.$forceUpdate();
      if (this.$store.state.loginAndRegister.secureMethodCount > 1) {
        this.retrieveMethodWindowFlag = true;
      } else if (this.$store.state.loginAndRegister.secureMethodCount !== 0) {
        // 获取安全方式的key，赋值给状态管理器
        for (let key in this.$store.state.loginAndRegister
          .accountSecureInfomation) {
          this.$store.commit("setRetrieveMethodIndex", key);
        }
        // 默认直接选中，进入对应验证窗口
        this.enterTheSelectedMethod();
      } else {
        console.log("安全方式的计数数量和安全方式的实际数量不匹配");
      }
    },
    // 进入选中的找回方法对应的窗口
    enterTheSelectedMethod() {
      Object.assign(this.$data, this.$options.data.call(this));
      this.$forceUpdate();
      if (
        this.$store.state.loginAndRegister.retrieveMethodIndex === "securePhone"
      ) {
        this.verifyMobilePhoneWindowFlag = true;
      } else if (
        this.$store.state.loginAndRegister.retrieveMethodIndex === "secureEmail"
      ) {
        this.verifyEmailWindowFlag = true;
      } else if (
        this.$store.state.loginAndRegister.retrieveMethodIndex ===
        "secureQuestionAnswers"
      ) {
        this.verifyQAWindowFlag = true;
      }
    },
    // 验证成功，进入设置新密码窗口
    enterTheSetNewPasswordProcess() {
      Object.assign(this.$data, this.$options.data.call(this));
      this.$forceUpdate();
      this.setNewPasswordWindowFlag = true;
    },
    // 点击进入注册流程
    enterTheRegisterProcess() {
      Object.assign(this.$data, this.$options.data.call(this));
      this.$forceUpdate();
      this.registerWindowFlag = true;
    },
    // 点击进入隐私政策窗口
    enterTheAgreementWindow() {
      Object.assign(this.$data, this.$options.data.call(this));
      this.$forceUpdate();
      this.privacyAgreementWindowFlag = true;
    },
    // 找回密码，设置新的密码
    async setNewPassword(params) {
      /**
       *  生成私钥，并上传
       */
      //  登录密码，验证成功
      const [aesEncryptPrivateKey, publicKey] = await createPGPKey(params.opwd);
      //  AES对私钥进行加密
      localStorage.setItem("aesEncryptPrivateKey", aesEncryptPrivateKey);
      localStorage.setItem("publicKey", publicKey);
      delete params.opwd;
      params.privateKey = aesEncryptPrivateKey;
      params.publicKey = publicKey;
      this.isloading = true;
      let result = await resetLoginPassword(params);
      this.isloading = false;
      // 设置新密码成功，跳回登录窗口
      if (result && result.code === 200) {
        if (this.directly_enter_password_recovery_window) {
          this.closeDialog();
        } else {
          this.$store.commit("setGlobalSuccessTipsStatus", true);
          this.enterTheLoginProcess();
        }
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
    },
    // 提交注册信息
    async handlerRegister(register) {
      let params = {
        password: this.fnencrypt(register.userPassword),
        username: register.userName,
      };
      this.isloading = true;
      const result = await registerNew(params).catch((e) => {});
      this.isloading = false;
      if (result && result.code === 200) {
        let login = {
          userName: register.userName,
          userPassword: register.userPassword,
          remember: true,
        };
        // 注册成功后，直接登录一次
        await this.handleLogin(login);
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
    },
    // 开始提交登录信息
    async handleLogin(login) {
      this.isloading = true;
      login.successFn = () => {
        this.isloading = false;
      };
      await this.global_login(login, this.teamRegister);
    },
    // 选择第三方登录的事件
    selectOtherLoginType(typeName) {
      this.isloading = true;
      if (typeName === "facebook" || typeName === "twitter") {
        this.loginGloble(typeName);
      } else {
        this.otherWayLogin(typeName);
      }
    },
    // 用于facebook和twitter第三方登录
    loginGloble(network) {
      let provider = hello(network);
      provider.login().then(
        // eslint-disable-next-line no-unused-vars
        (response) => {
          console.log(response);
          provider.api("me", (resp) => {
            if (resp && resp.id) {
              let { id_str, name, profile_image_url_https, id } = resp;
              this.handlerAutoRegister({
                uuid: id_str || id,
                username: name,
                avatar: profile_image_url_https,
                source: network,
                language: localStorage.getItem("langCode"),
              });
            }
          });
        },
        (e) => {
          if (e.error.code !== "cancelled") {
            this.changeVisible();
            this.$alert(e.error.message);
          }
        }
      );
    },
    // 用于三方登录
    async otherWayLogin(type) {
      const result = await this.$http({
        url: "rest/oauth/render/" + type,
        params: { source: type },
        withCredentials: false,
      }).catch((e) => {});
      if (result && result.data) {
        location.href = result.data.data;
      }
    },
    // 第三方登录过后用于登录的方法
    async handlerAutoRegister(params) {
      const result = await this.$http({
        url: "/rest/oauth/register",
        data: params,
        method: "post",
      }).catch((e) => {
        console.log(e);
      });
      this.isloading = false;
      if (result && result.data) {
        this.isloading = true;
        await this.handlerLoginSuccessEmit(result.data.data);
        this.isloading = false;
        // 本地存入用户信息后，刷新页面
        location.reload();
      }
    },
    // 登录成功后，开始整理存储信息
    handlerLoginSuccessEmit(item, pwd) {
      return new Promise(async (resolve, reject) => {
        let userinfo = {
          loginState: "melinkedLogin",
          id: (item.provider_id || item.providerId).toString(),
          name: item.name,
          account: item.account,
          avatar: item.profile_photo || item.portfolio,
          uc_id: item.uc_id || item.ucId,
          country: item.country,
          gender: item.gender,
          token: item.token,
          manager_role: item.manager_role,
          identity: item.identity,
          hasYcResumeGroup: item.hasYcResumeGroup || false,
          updatePassword: item.updatePassword === false ? false : true,
        };
        if (item.customerGroup && Object.keys(item.customerGroup).length) {
          userinfo.groupId = item.customerGroup.groupId || "";
          userinfo.groupName = item.customerGroup.groupName || "";
          userinfo.groupHeader = item.customerGroup.groupHeader || "";
        }
        // 更新状态管理用户基本信息
        this.$store.commit("setUserinfo", userinfo);
        // 强密码注册后，自动登录后，生成公私钥
        let result = await currentLoginVipStatus();
        if (result.code == 200) {
          if (
            !result.data.data.privateKey &&
            this.verificationRegExp.test(pwd)
          ) {
            await this.handlerRegisterOrLoginSuccessCreatePGP(pwd);
          }
        }
        // 执行成功
        resolve("success");
      });
    },
    // 注冊成功，并且是強密碼的情況下，生成公鑰私鑰
    async handlerRegisterOrLoginSuccessCreatePGP(pwd) {
      const [aesEncryptPrivateKey, publicKey] = await createPGPKey(pwd);
      //  AES对私钥进行加密
      localStorage.setItem("aesEncryptPrivateKey", aesEncryptPrivateKey);
      localStorage.setItem("publicKey", publicKey);
      await addOrUpdatePgpKey({
        privateKey: aesEncryptPrivateKey,
        publicKey: publicKey,
      });
    },
    //游客登陆
    async enterTheTourist() {
      this.isloading = true;
      this.handlerInitToursits(true)
        .then(() => {
          this.isloading = false;
          location.reload();
        })
        .catch((message) => {
          this.isloading = false;
          this.$message({
            type: "error",
            message: `${this.$t("loginerror")}:${message}`,
          });
        });
    },
    //团队注册
    teamRegister(type = 0) {
      Object.assign(this.$data, this.$options.data.call(this));
      this.$forceUpdate();
      this.$store.commit("setTeamWindowType", type);
      this.$store.commit("setTeamWindowFlag", true);
    },
  },
};
</script>
<style lang="stylus" scoped>
.transitionStyle-enter-active, .transitionStyle-leave-active
  transition: opacity .3s, transform .3s;
.transitionStyle-enter
  opacity: 0;
  transform translate3d(120px,0,0);
.transitionStyle-leave-to
  opacity: 0;
  transform translate3d(-120px,0,0);
.dialogHeader
  width 100%;
  height 68px;
  box-sizing border-box;
  padding 17px 16px 0 0;
  .iconStyle
    float right;
    cursor pointer;
    font-size 16px;
    color #CCCCCC;
.dialogBody
  width 100%;
  height 453px;
  // height 514px;
  position relative;
</style>
<style lang="stylus">
.loginAndRegisterStyle_520
  width 448px !important;
  height 520px !important;
  margin 0 !important;
  border-radius: 6px !important;
  box-sizing border-box;
  // 这是窗口内布局用的，用于和底部返回键布局
  .loginAndRegisterCommonContent
    width 100%;
    flex 1;
    min-height 0;
  .windowFooter
    width 100%;
    height 34px;
    flex-shrink 0;
    overflow hidden;
    box-sizing border-box;
    display flex;
    align-items center;
    justify-content space-between;
    .backToPrevious
      width 20px;
      height 20px;
      display flex;
      align-items center;
      justify-content center;
      cursor pointer;
      .iconStyle
        font-size 20px;
        color #8F8F8F;
    .startRegister
      padding 0 15px;
      height 100%;
      line-height 34px;
      background #F4F5F7;
      border-radius 17px;
      overflow hidden;
      cursor pointer;
      font-size 14px;
      color #919191;
      user-select none;
      .iconStyle
        font-size 14px;
        font-weight bold;
        color #666666;
        margin-right 5px;
  .el-dialog__header
    display none !important;
  .el-dialog__body
    padding 0 !important;
    width 100% !important;
    height 100% !important
    overflow hidden !important;
    border-radius: 6px !important;
    .inputAreaBox
      width 100%;
      overflow hidden;
      margin-top 40px;
      min-height 110px;
      .inputOuter
        width 100%;
        height 46px;
        overflow hidden;
        margin-bottom 18px;
        &:last-child
          margin-bottom 0;
        .iconStyle
          color #666666;
          font-size 20px;
</style>
