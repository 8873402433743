var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "windowBody" }, [
    _c(
      "div",
      {
        staticClass: "loginAndRegisterCommonContent",
        attrs: { id: "verifyMobilePhoneWindow" }
      },
      [
        _c("WindowHeader", [
          _vm._v(_vm._s(_vm.$t("verification")) + _vm._s(_vm.$t("phone")))
        ]),
        _c("div", { staticClass: "inputAreaBox" }, [
          _c(
            "div",
            { staticClass: "inputOuter" },
            [
              _c(
                "CommonInput",
                {
                  attrs: {
                    readOnly: true,
                    defaultInputText: _vm.showDefaultPhone()
                  }
                },
                [
                  _c("el-image", {
                    staticStyle: { width: "11px", height: "16px" },
                    attrs: {
                      src: require("../../../assets/images/newLogin/phoneIcon.png"),
                      fit: "fill"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("div", { staticClass: "inputOuter verificationCodeBox" }, [
            _c(
              "div",
              { staticClass: "leftInputBox" },
              [
                _c(
                  "CommonInput",
                  { on: { inputChange: _vm.verificationCode } },
                  [
                    _c("el-image", {
                      staticStyle: { width: "14px", height: "16px" },
                      attrs: {
                        src: require("../../../assets/images/newLogin/securityIcon.png"),
                        fit: "fill"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "rightSendButton",
                class: _vm.hasSendVerificationCode ? "notAllowedStyle" : "",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.sendVerificationCode.apply(null, arguments)
                  }
                }
              },
              [
                !_vm.hasSendVerificationCode
                  ? _c("el-image", {
                      staticStyle: { width: "16px", height: "16px" },
                      attrs: {
                        src: require("../../../assets/images/newLogin/sendIcon.png"),
                        fit: "fill"
                      }
                    })
                  : _c("span", { staticClass: "countdownStyle" }, [
                      _vm._v(_vm._s(_vm.countdownText) + "S")
                    ])
              ],
              1
            )
          ])
        ]),
        _c(
          "div",
          {
            staticClass: "nextStepButton",
            class: _vm.allowedNextStep ? "commomButtonHover" : "",
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.startNextStep.apply(null, arguments)
              }
            }
          },
          [_c("span", { staticClass: "iconfont_Me icon-next iconStyle" })]
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "windowFooter", style: { marginBottom: "50px" } },
      [
        _c(
          "div",
          {
            staticClass: "backToPrevious",
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.goBackToThePreviousStep.apply(null, arguments)
              }
            }
          },
          [_c("span", { staticClass: "iconfont_Me icon-return iconStyle" })]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }