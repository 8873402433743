<template>
  <div class="windowBody">
    <div class="dialogLogo">
      <el-image
        style="height: 100%"
        :src="require('../../../assets/logo_2.png')"
        fit="contain"
      ></el-image>
    </div>
    <div class="otherLogin">
      <span
        class="iconfont_Me otherLoginButton"
        v-for="(item, index) in otherLoginArr"
        :key="item.className"
        :class="[item.className, item.fontClassName]"
        @click.stop="selectOtherLoginType(item.typeName)"
      ></span>
    </div>
    <div
      class="loginButton commonStyle commomButtonHover"
      @click.stop="enterTheLoginProcess"
    >
      {{ $t("login") }}
    </div>
    <div
      class="registerButton bg commonStyle"
      @click.stop="enterTheRegisterProcess"
      :style="{ 'margin-top': showTouristsAndTeams ? '' : '20px' }"
    >
      {{ $t("register") }}
    </div>
    <!-- 游客登录 -->
    <div
      class="registerButton commonStyle"
      @click.stop="enterTheTourist"
      v-if="showTouristsAndTeams"
    >
      {{ $t("tourists") }}
    </div>
    <!-- 团队注册 -->
    <div
      class="teamRegisterButton commonStyle"
      @click.stop="teamRegister"
      v-if="showTouristsAndTeams"
    >
      {{ $t("teamRegistration") }}
    </div>
  </div>
</template>
<script>
export default {
  name: "initialWindow",
  data() {
    return {
      otherLoginArr: [
        {
          className: "weixin",
          typeName: "wechat",
          fontClassName: "icon-icon-test8",
        },
        {
          className: "qq",
          typeName: "qq",
          fontClassName: "icon-qq",
        },
        {
          className: "weibo",
          typeName: "sina",
          fontClassName: "icon-icon-test2",
        },
        {
          className: "linkin",
          typeName: "linkedin",
          fontClassName: "icon-icon-test1",
        },
        {
          className: "facebook",
          typeName: "facebook",
          fontClassName: "icon-icon-test",
        },
        {
          className: "twitter",
          typeName: "twitter",
          fontClassName: "icon-icon-test6",
        },
      ],
      // 显示游客和团队入口
      showTouristsAndTeams: true,
    };
  },
  mounted() {
    if (localStorage.getItem("joinMembership")) {
      this.showTouristsAndTeams = false;
    }
  },
  methods: {
    // 点击开始进入登录流程
    enterTheLoginProcess() {
      this.$emit("enterTheLoginProcess");
    },
    // 点击开始进入注册流程
    enterTheRegisterProcess() {
      this.$emit("enterTheRegisterProcess");
    },
    // 选择第三方登录方式触发事件
    selectOtherLoginType(typeName) {
      this.$emit("selectOtherLoginType", typeName);
    },
    // 游客登陆
    enterTheTourist() {
      this.$emit("enterTheTourist");
    },
    //团队注册
    teamRegister() {
      this.$emit("teamRegister");
    },
  },
};
</script>
<style lang="stylus" scoped>
.windowBody
  width 100%;
  height 100%;
  box-sizing border-box;
  padding 23px 0 26px 0;
  display flex;
  flex-flow column;
  align-items center;
  justify-content flex-start;
  background url('../../../assets/images/newLogin/loginAndRegisterBg.png') no-repeat;
  background-size 100% 100%;
  .dialogLogo
    height 60px;
    width 100%;
    display flex;
    align-items center;
    box-sizing border-box;
    justify-content center;
  .otherLogin
    width 100%;
    height 46px;
    display flex;
    margin-top 50px;
    align-items center;
    box-sizing border-box;
    justify-content center;
    .weixin
      color #46BB36;
    .qq
      color #498BEE;
    .weibo
      color #EF4B4B;
    .linkin
      color #0077B5;
    .facebook
      color #3375CB;
    .twitter
      color #00BDF3;
    .otherLoginButton
      cursor pointer;
      margin-right 15px;
      display inline-block;
      font-size 46px;
      transition transform .2s;
      &:last-child
        margin-right 0 !important;
      &:hover
        transform scale(1.1)
  .commonStyle
    width 360px;
    height 46px;
    cursor pointer;
    border-radius 4px;
    overflow hidden;
    text-align center;
    line-height 46px;
    font-size 14px;
    user-select none;
  .loginButton
    margin-top 42px;
    color #ffffff;
  .registerButton
    margin-top 16px;
    color #33CC66;
    box-sizing border-box;
    border 1px solid #33CC66;
    transition background .3s;
    &:hover
      background rgba(242,252,248,0.6);
  .bg{
      background: rgba(51,204,102,0.1)
  }

  .teamRegisterButton
    font-size: 14px;
    text-align: left;
    color: #33cc66;
</style>
