<template>
  <div class="windowBody">
    <div class="loginAndRegisterCommonContent" id="verifyQAWindow">
      <WindowHeader
        >{{ $t("verification") }}{{ $t("secureQuestion") }}</WindowHeader
      >
      <div class="inputAreaBox">
        <div
          class="inputOuterStyle"
          v-for="item in $store.state.loginAndRegister.accountSecureInfomation
            .secureQuestionAnswers"
          :key="item.questionId"
        >
          <QAInput :questionItem="item" @getAnswer="getAnswer"></QAInput>
        </div>
      </div>
      <div
        class="nextStepButton"
        :class="allowedNextStep ? 'commomButtonHover' : ''"
        @click.stop="startNextStep"
      >
        <span class="iconfont_Me icon-check iconStyle"></span>
      </div>
    </div>
    <div class="windowFooter" :style="{ marginBottom: '50px' }">
      <div class="backToPrevious" @click.stop="goBackToThePreviousStep">
        <span class="iconfont_Me icon-return iconStyle"></span>
      </div>
    </div>
  </div>
</template>
<script>
import WindowHeader from "./windowHeader";
import QAInput from "../../components/inputComponent/QAInput";
import { checkAnswers } from "@/api/newVersion/loginAndRegister";
export default {
  name: "verifyQAWindow",
  components: {
    WindowHeader,
    QAInput,
  },
  computed: {
    // 是否可以进入下一步
    allowedNextStep() {
      return this.submitQuestionAnswers.every((item) => item.answer !== "");
    },
  },
  data() {
    return {
      // 用于提交的回答数据
      submitQuestionAnswers: this.$store.state.loginAndRegister.accountSecureInfomation.secureQuestionAnswers.map(
        (item) => {
          item = _.cloneDeep(item);
          return item;
        }
      ),
    };
  },
  methods: {
    // 返回上一步
    goBackToThePreviousStep() {
      // 清空记录的问题和答案
      this.$store.commit("setNewSecureQuestionAnswers", []);
      // 如果设置了多种找回方式，就返回选择找回方式窗口，否则就返回验证登录账号窗口
      if (this.$store.state.loginAndRegister.secureMethodCount > 1) {
        this.$emit("enterTheRetrieveMethodProcess");
      } else {
        this.$emit("enterTheForgetPwdProcess");
      }
    },
    // 获取到回答的数据
    getAnswer(answerItem) {
      this.submitQuestionAnswers = this.submitQuestionAnswers.map((item) => {
        if (item.questionId === answerItem.questionId) {
          item = answerItem;
        }
        return item;
      });
    },
    // 进入下一步
    async startNextStep() {
      if (this.allowedNextStep) {
        let params = {
          providerId:
            this.$store.state.loginAndRegister.accountSecureProviderId ||
            this.$store.state.accountStore.secureInfo.providerId,
          secureQuestionAnswers: this.submitQuestionAnswers.map((item) => {
            let answer = {};
            answer.questionId = item.questionId;
            answer.answer = item.answer;
            return answer;
          }),
        };
        let result = await checkAnswers(params);
        if (result && result.data.data) {
          // 验证成功后，将问题和其对饮的答案存入状态管理器，以便修改密码的时候用
          this.$store.commit(
            "setNewSecureQuestionAnswers",
            params.secureQuestionAnswers
          );
          // 验证成功，跳转密码设置窗口
          this.$emit("enterTheSetNewPasswordProcess");
        } else {
          this.$message({
            type: "error",
            message: result.message,
          });
        }
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
.windowBody
  width 100%;
  height 100%;
  padding 0 45px;
  box-sizing border-box;
  display flex;
  flex-flow column;
  align-items center;
  justify-content space-between;
  .inputAreaBox
    width 100%;
    overflow hidden;
    margin-top 40px;
    .inputOuterStyle
      margin-bottom 20px;
  .nextStepButton
    width 100%;
    height 46px;
    margin-top 10px;
    border-radius 4px;
    overflow hidden;
    background #E6E6E6;
    display flex;
    align-items center;
    justify-content center;
    cursor not-allowed;
    .iconStyle
      color #FFFFFF;
      font-size 16px;
</style>
